<template src="./AppIcon.html"></template>

<script>
export default {
    props: {
        dxIcon: {
            type: Boolean,
            required: false,
            default: false
        },
        mdi: {
            type: Boolean,
            required: false,
            default: true
        },
        color: {
            type: String,
            required: false,
            default: ""
        },
        icon: {
            type: String,
            required: true
        },
        left: {
            type: Boolean,
            required: false,
            default: false
        },
        right: {
            type: Boolean,
            required: false,
            default: false
        },
        large: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        computedClasses() {
            const classes = ["icon"];

            if (this.mdi) {
                classes.push("mdi");
                classes.push(`mdi-${this.icon}`);
            } else if (this.dxIcon) {
                classes.push(this.icon);
            }

            if (this.left) classes.push("left");
            if (this.right) classes.push("right");
            if (this.large) classes.push("large");

            return classes;
        },
        styles() {
            if (this.color) {
                return { color: this.color };
            }
            return null;
        }
    }
};
</script>

<style src="./AppIcon.css" scoped></style>
