import Vue from "vue";
import VueRouter from "vue-router";
import ManualChecks from "@/pages/ManualChecks";
import StatusNumbers from "@/pages/StatusNumbers";
import Dealers from "@/pages/Dealers";
import Campaigns from "@/pages/Campaigns";
import Vehicles from "@/pages/Vehicles";
import ScoreSteps from "@/pages/ScoreSteps";
import Rules from "@/pages/Rules";
import Blacklists from "@/pages/Blacklists";
import Login from "@/pages/Login";
import Users from "@/pages/Users";
import EditUser from "@/pages/EditUser";
import CreateUser from "@/pages/CreateUser";
import PreferredDealers from "@/pages/PreferredDealers";
import Monitoring from "@/pages/Monitoring";
import Error from "@/pages/Error";

Vue.use(VueRouter);

const routes = [
    {
        path: "/manual-checks",
        name: "ManualChecks",
        component: ManualChecks,
        meta: { title: "Manual checks" }
    },
    {
        path: "/status-numbers",
        name: "StatusNumbers",
        component: StatusNumbers,
        meta: { title: "Status numbers" }
    },
    {
        path: "/dealers",
        name: "Dealers",
        component: Dealers,
        meta: { title: "Dealers" }
    },
    {
        path: "/campaigns",
        name: "Campaigns",
        component: Campaigns,
        meta: { title: "Campaigns" }
    },
    {
        path: "/vehicles",
        name: "Vehicles",
        component: Vehicles,
        meta: { title: "Vehicles" }
    },
    {
        path: "/score-steps",
        name: "ScoreSteps",
        component: ScoreSteps,
        meta: { title: "Score steps" }
    },
    {
        path: "/rules",
        name: "Rules",
        component: Rules,
        meta: { title: "Rules" }
    },
    {
        path: "/blacklists",
        name: "Blacklists",
        component: Blacklists,
        meta: { title: "Blacklists" }
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            layout: "authLayout",
            title: "Login",
            allowAnonymous: true
        }
    },
    {
        path: "/users",
        name: "Users",
        component: Users,
        meta: { title: "Users" }
    },
    {
        path: "/users/edit/:id",
        name: "editUser",
        component: EditUser,
        meta: { title: "Edit User" }
    },
    {
        path: "/users/create",
        name: "createUser",
        component: CreateUser,
        meta: { title: "Create User" }
    },
    {
        path: "/preferred-dealers",
        name: "PreferredDealers",
        component: PreferredDealers,
        meta: { title: "Preferred dealers" }
    },
    {
        path: "/",
        name: "Monitoring",
        component: Monitoring,
        meta: { title: "Monitoring" }
    },
    {
        path: "/error/:code",
        name: "error",
        component: Error,
        meta: {
            title: "Error"
        }
    },
    {
        path: "*",
        name: "404",
        redirect: () => "/error/404"
    }
];

const router = new VueRouter({
    routes: routes,
    mode: "history"
});

const DEFAULT_TITLE = "LPS MANAGEMENT | BMW LEAD PRESELECTION";

router.afterEach((to) => {
    let pageTitle = DEFAULT_TITLE;
    Vue.nextTick(() => {
        if (to.meta?.title) pageTitle = pageTitle.concat(" - ", to.meta.title);
        document.title = pageTitle;
    });
});

export default router;
