import {
    fetchByPage,
    saveOrUpdate,
    sync
} from "@/utils/api/blacklistNames.js";

const state = {
    blacklistNames: [],
    totalCount: null
};

const getters = {
    getBlacklistNames: (state) => {
        return state.blacklistNames;
    },
    getTotalCount: (state) => {
        return state.totalCount;
    }
};

const mutations = {
    SET_BLACKLIST_NAMES: (state, data) => {
        state.blacklistNames = data.data.blacklistNames;
        state.totalCount = data.data.totalCount;
    }
};

const actions = {
    fetchBlacklistNames({ commit }, query) {
        return new Promise((resolve, reject) => {
            fetchByPage(query)
                .then((response) => {
                    commit("SET_BLACKLIST_NAMES", response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    saveOrUpdateBlacklistName(_, data) {
        return new Promise((resolve, reject) => {
            saveOrUpdate(data)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    syncBlacklistNames() {
        return new Promise((resolve, reject) => {
            sync()
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
