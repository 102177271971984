const STATUS_NUMBERS = "statusNumbers"
const SCORE_STEPS = "scoreSteps"
const CAMPAIGNS = "campaigns"
const VEHICLES = "vehicles"
const DEALERS = "dealers"
const RULES = "rules"
const BLACKLIST_DIALING_CODES = "blacklistDialingCodes"
const BLACKLIST_EMAILS = "blacklistEmails"
const BLACKLIST_NAMES = "blacklistNames"

export default {
    STATUS_NUMBERS: STATUS_NUMBERS,
    SCORE_STEPS: SCORE_STEPS,
    CAMPAIGNS: CAMPAIGNS,
    VEHICLES: VEHICLES,
    DEALERS: DEALERS,
    RULES: RULES,
    BLACKLIST_DIALING_CODES: BLACKLIST_DIALING_CODES,
    BLACKLIST_EMAILS: BLACKLIST_EMAILS,
    BLACKLIST_NAMES: BLACKLIST_NAMES
}