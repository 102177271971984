import { fetch, updateActive, sync } from "@/utils/api/scoreSteps.js";

const state = {
    scoreStepTables: []
};

const getters = {
    getScoreStepTables: (state) => {
        return state.scoreStepTables;
    }
};

const mutations = {
    SET_SCORE_STEPS: (state, data) => {
        state.scoreStepTables = [];
        let brands = [...new Set(data.map((el) => el.brand))].sort();
        brands.forEach((brand) => {
            let scoreStepTable = { title: brand, scoreSteps: [] };
            scoreStepTable.scoreSteps = data
                .filter((el) => el.brand === brand)
                .sort((a, b) => b.step - a.step);
            state.scoreStepTables.push(scoreStepTable);
        });
    }
};

const actions = {
    fetchScoreSteps({ commit }) {
        return new Promise((resolve, reject) => {
            fetch()
                .then((response) => {
                    commit("SET_SCORE_STEPS", response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateActiveScoreStep(_, key) {
        return new Promise((resolve, reject) => {
            updateActive(key)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    syncScoreSteps() {
        return new Promise((resolve, reject) => {
            sync()
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
