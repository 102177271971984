import service from "./api.js";

export function fetchByPage(data) {
    return service({
        url: "/vehicles",
        method: "post",
        data
    });
}

export function fetchUnprocessedCount(data) {
    return service({
        url: "/vehicles/unprocessed-count",
        method: "get",
        data
    });
}

export function updateStatus(data) {
    return service({
        url: "/vehicles/update-status",
        method: "put",
        data
    });
}

export function update(data) {
    return service({
        url: "/vehicles/update",
        method: "put",
        data
    });
}

export function multipleUpdate(data) {
    return service({
        url: "/vehicles/multiple-update",
        method: "put",
        data
    });
}

export function sync() {
    return service({
        url: "/vehicles/sync",
        method: "get"
    });
}

export function multipleForwarding() {
    return service({
        url: "/vehicles/multiple-forwarding",
        method: "put"
    });
}
