<template src="./DataGridHeader.html"></template>

<script>
export default {
    name: "DataGridHeader",
    props: {
        title: String,
        subTitle: String,
    }
};
</script>

<style src="./DataGridHeader.css"></style>
