<template src="./SideMenuTitle.html"></template>
<script>
export default {
    props: {
        to: {
            type: String,
            required: false
        },
        title: {
            type: String,
            required: true
        }
    }
};
</script>

<style src="./SideMenuTitle.css" scoped></style>
