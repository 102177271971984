<template src="./Users.html">
</template>

<script lang="js">
import DataGridHeader from "@/components/DataGridHeader";
import { store } from "@/store";
import {
    DxDataGrid,
    DxEditing,
    DxPaging,
    DxPager,
    DxColumn,
    DxFilterRow,
    DxLookup,
    DxButton as DataGridButton,
    DxToolbar,
    DxItem as GridDxItem,
    DxColumnChooser
} from "devextreme-vue/data-grid";
import DxButton from "devextreme-vue/button";
import { userModule } from "@/store/modules/users";
import { roleModule } from "@/store/modules/roles"

export default {
    components: {
        GridDxItem,
        DxToolbar,
        DataGridHeader,
        DxDataGrid,
        DxEditing,
        DxPaging,
        DxPager,
        DxColumnChooser,
        DxColumn,
        DxFilterRow,
        DxLookup,
        DataGridButton,
        DxButton
    },
    methods: {
        async loadUsers() {
            await store.dispatch("users/loadUsers");
        },
        async loadRoles() {
            await store.dispatch("roles/loadRoles");
        },
        addNewUser() {
            this.$router.push("/users/create");
        },
        editUser(e) {
            if (e.row.key) {
                this.$router.push("/users/edit/" + e.row.key);
            }
        },
        isActive(e) {
            return !e.row.data.active;
        },
        customizeTrueFalse(e) {
            return e.value ? 'Aktiv' : 'Inaktiv';
        },
        showColumnChooser() {
            this.gridInstance.showColumnChooser();
        }
    },
    computed: {
        ...userModule.mapGetters({ users: "getUsers" }),
        ...roleModule.mapGetters({ roles: "getRoles" }),
        gridInstance() {
            return this.$refs["data-grid"].instance;
        }
    },
    created() {
        this.loadUsers();
        this.loadRoles();
    },
    data() {
        return {
            addButtonOptions: {
                icon: 'plus',
                text: 'Neu',
                hint: 'Neuen Benutzer anlegen',
                onClick: () => {
                    this.addNewUser();
                },
            }
        }
    }
};
</script>


<style src="./Users.css" lang="css" scoped></style>