<template src="./Login.html"></template>

<script lang="js">
import logo from "@/assets/img/BMW_logo_(white).svg";
import DxForm, {
    DxSimpleItem,
    DxLabel,
    DxRequiredRule,
    DxStringLengthRule,
    DxEmailRule,
    DxEmptyItem,
    DxButtonItem
} from "devextreme-vue/form";
import { store } from "@/store/index";
import Errors from "./BackendResponseErrors";
import notify from "devextreme/ui/notify";

export default {
    components: {
        DxForm,
        DxLabel,
        DxSimpleItem,
        DxRequiredRule,
        DxStringLengthRule,
        DxEmailRule,
        DxEmptyItem,
        DxButtonItem
    },
    methods: {
        setErrors(errors) { 
            store.commit("account/setErrors", errors);
        },
        async submit() {
            await store.dispatch("account/logUserIn", {
                username: this.loginModel.email,
                password: this.loginModel.password
            });

            const sessionErrors = store.getters["account/getErrors"];
            this.setErrors(null);
            
            if (sessionErrors !== undefined && sessionErrors !== null) {
                if (sessionErrors[0].includes(Errors.BadRequest.Error)) {
                    notify(Errors.BadRequest.Message, "error", 10000);
                } else {
                    notify(
                        sessionErrors[1] ?? Errors.Default.Message,
                        "error",
                        10000
                    );
                }
            } else {
                this.$router.push(
                    this.$route.query.redirecturl ?? "/"
                );
            }
        }
    },
    data: function () {
        return {
            logo: logo,
            loginModel: {
                email: "",
                password: ""
            },
            passwordOptions: {
                placeholder: "Passwort",
                mode: "password"
            },
            emailOptions: {
                placeholder: "E-Mail-Adresse eingeben"
            },
            buttonOptions: {
                text: "Login",
                type: "default",
                height: 40,
                width: 120,
                useSubmitBehavior: true
            }
        }
    }
}
</script>

<style src="./Login.css" lang="css" scoped></style>
