import api from "./api";

async function logUserIn(
    username,
    password
) {
    if (username.length == 0 || password.length == 0) {
        throw "Username or password null or empty";
    }

    const formData = new FormData();

    formData.append("username", username);
    formData.append("password", password);

    let response = await api.post("/user/login", formData);

    if (response.status == 200) {
        response = await api.get("/user");

        return {
            user: response.data,
            validUntil: Date.now() + 60000 // 60 seconds
        };
    }

    throw response.status;
}

async function verifyLogin() {
    try {
        const response = await api.get("/user");

        if (response.status == 200) {
            return {
                user: response.data,
                validUntil: Date.now() + 60000 // 60 seconds
            };
        }

        throw response.status;
    } catch {
        return null;
    }
}

async function logUserOut() {
    const response = await api.post("/user/logout");

    if (response.status != 200) throw response.data;
}

async function requestPasswordReset(email) {
    const formData = new FormData();
    formData.append("emailaddress", email);
    const response = await api.post("/user/password/request", formData);

    if (response.status != 200) throw response.data;
}

async function resetPassword(
    token,
    newPassword
) {
    const formData = new FormData();
    formData.append("newPassword", newPassword);
    const response = await api.post(
        "/user/password/reset?token=" + token,
        formData
    );

    if (response.status != 200) throw response.data;
}

async function activateAccount(token, password) {
    const formData = new FormData();
    formData.append("password", password);
    const response = await api.post("/user/activate?token=" + token, formData);

    if (response.status != 200) throw response.data;
}

export default {
    logUserIn,
    verifyLogin,
    requestPasswordReset,
    resetPassword,
    activateAccount,
    logUserOut
};
