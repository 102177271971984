<template src="./EmailRecipientsPopup.html"></template>

<script>
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
import DxButton from "devextreme-vue/button";
import { mapGetters } from "vuex";
import { DxScrollView } from "devextreme-vue/scroll-view";

export default {
    components: {
        DxPopup,
        DxToolbarItem,
        DxButton,
        DxScrollView,
    },
    name: "EmailRecipientsPopup",
    props: {
        showDialog: Boolean,
    },
    data() {
        return {
            okButtonOptions: {
                text: "Schließen",
                onClick: this.closingDialog
            }
        };
    },
    methods: {
        closingDialog() {
            this.$emit("update:showDialog", false);
        }
    },
    computed: {
         ...mapGetters("campaigns", ["getEmailRecipients", "getEmailCCRecipients"])
    }
}
</script>
