import { sync, fetch, saveOrUpdate } from "@/utils/api/rules.js";

const state = {
    rules: []
};

const getters = {
    getRules: (state) => {
        return state.rules;
    }
};

const mutations = {
    SET_RULES: (state, data) => {
        state.rules = data;
    }
};

const actions = {
    fetchRules({ commit }, query) {
        return new Promise((resolve, reject) => {
            fetch(query)
                .then((response) => {
                    commit("SET_RULES", response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    saveOrUpdateRules(_, data) {
        return new Promise((resolve, reject) => {
            saveOrUpdate(data)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    syncRules() {
        return new Promise((resolve, reject) => {
            sync()
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
