import service from "./api.js";

export function fetch() {
    return service({
        url: "/status-numbers",
        method: "get"
    });
}

export function saveOrUpdate(data) {
    return service({
        url: "/status-numbers/save-or-update",
        method: "post",
        data
    });
}

export function sync() {
    return service({
        url: "/status-numbers/sync",
        method: "get"
    });
}
