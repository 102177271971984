<template src="./GenericDataGrid.html"></template>

<script>
import {
    DxDataGrid,
    DxColumnChooser,
    DxColumn,
    DxEditing,
    DxHeaderFilter,
    DxFilterRow,
    DxToolbar,
    DxPager,
    DxPaging,
    DxLookup,
    DxRequiredRule,
    DxForm,
    DxTexts,
    DxItem as GridDxItem
} from "devextreme-vue/data-grid";
import {
    DxSimpleItem,
    DxGroupItem,
    DxLabel,
    DxItem as FormDxItem
} from "devextreme-vue/form";
import { DxCustomRule } from "devextreme-vue/validator";
import { DxSwitch } from "devextreme-vue/switch";
import DxButton from "devextreme-vue/button";
import "devextreme/ui/text_area";
import "devextreme-vue/lookup";
import { updateStatuses } from "@/constants/updateStatuses.js";
import { mapGetters, mapActions } from "vuex";

export default {
    components: {
        GridDxItem,
        FormDxItem,
        DxGroupItem,
        DxDataGrid,
        DxColumnChooser,
        DxColumn,
        DxButton,
        DxForm,
        DxToolbar,
        DxSimpleItem,
        DxEditing,
        DxHeaderFilter,
        DxFilterRow,
        DxPager,
        DxPaging,
        DxSwitch,
        DxLabel,
        DxLookup,
        DxRequiredRule,
        DxTexts,
        DxCustomRule
    },
    name: "GenericDataGrid",
    props: {
        isSynchronizable: Boolean,
        isProcessable: Boolean,
        isNew: Boolean,
        isParkable: Boolean,
        unsyncChangesTable: String,
        allowMultipleUpdateStatus: Boolean,
        allowAllPaging: Boolean,
        allowBatchMode: Boolean,
        allowAdding: Boolean,
        allowPaging: Boolean,
        allowFilterRow: Boolean,
        allowHeaderFilter: Boolean,
        gridColumns: Array,
        defaultColumns: Array,
        formStructure: Array,
        defaultValues: Object,
        dataSource: [Object, Array],
        groupCount: Number,
        colCount: Number,
        forwardButtonText: String,
        rejectButtonText: String,
        editRowText: String,
        addRowText: String,
        showOperationChooser: Boolean,
        noDataText: {
            default: "No data",
            type: String
        },
        showEmailRecipientButton: Boolean
    },
    data() {
        return {
            hasChanges: false,
            isBatchMode: false,
            pageSize: 20,
            currentPage: 0,
            pageSizes: this.allowAllPaging
                ? [20, 50, 100, "all"]
                : [20, 50, 100],
            currentRowIndex: 0,
            status: null,
            FORWARDED_STATUS: updateStatuses.forwarded,
            REJECTED_STATUS: updateStatuses.rejected,
            PARKED_STATUS: updateStatuses.parked
        };
    },
    computed: {
        ...mapGetters("unsyncChanges", ["hasUnsyncChanges"]),
        gridInstance() {
            return this.$refs["data-grid"].instance;
        },
        editorMode() {
            return this.isBatchMode ? "batch" : "form";
        },
        syncButtonColor() {
            if (this.hasUnsyncChanges(this.unsyncChangesTable)) {
                return "default";
            }
            return "primary";
        },
        saveButtonColor() {
            if (this.hasChanges) {
                return "success";
            }
            return "primary";
        },
        dataSourceTotalCount() {
            return this.gridInstance.getDataSource().totalCount();
        },
        isLastRecord() {
            return (
                this.dataSourceTotalCount ===
                this.currentPage * this.pageSize + this.currentRowIndex + 1
            );
        },
        isOnlyOneRecord() {
            return this.dataSourceTotalCount === 1;
        },
        modeSwitcherHint() {
            if (this.isBatchMode)
                return "Änderungen in mehreren Zeilen gleichzeitig durchführen";
            else return "Einzelne Einträge über ein Formular ändern";
        }
    },
    watch: {
        currentPage() {
            this.currentRowIndex = 0;
        },
        isBatchMode() {
            this.$emit("updateIsBatchMode", this.isBatchMode);
            this.hasChanges = false;
        }
    },
    methods: {
        ...mapActions("unsyncChanges", ["updateUnsyncChanges"]),
        isColumnVisible(column) {
            return this.defaultColumns.includes(column.title);
        },
        setDefaultValues(e) {
            if (this.defaultValues) {
                e.data = this.defaultValues;
            }

            this.$emit("updateRowStatus", true);
        },
        // e.key is taLpsRuleId
        // ID in table is lpsRuleId
        onEditingStart(e) {
            this.$emit("updateRowStatus", false);
            this.currentRowIndex = this.gridInstance.getRowIndexByKey(e.key);
        },
        async openRecord(e) {
            this.$emit("onContentReady");

            //Code snippet from DevExpress Support https://supportcenter.devexpress.com/ticket/details/t400242/dxdatagrid-how-to-change-position-of-column-chooser
            //Please note that this code uses an undocumented API and we cannot guarantee that it will work properly in future versions.
            //This is code for positioning column chooser
            var columnChooserView = e.component.getView("columnChooserView");
            if (!columnChooserView._popupContainer) {
                columnChooserView._initializePopupContainer();
                columnChooserView.render();
                columnChooserView._popupContainer.option("position", {
                    of: e.element,
                    my: "right top",
                    at: "right top",
                    offset: "0 50"
                });
            }
            //end of snippet

            if (this.editorMode === "form") {
                if (this.currentRowIndex < 0) this.currentRowIndex = 0;

                if (
                    this.gridInstance.getVisibleRows().length - 1 <
                    this.currentRowIndex
                ) {
                    this.currentRowIndex -= 1;
                }

                await this.$emit(
                    "updateProcessiable",
                    this.gridInstance.getVisibleRows()[this.currentRowIndex]
                        .data
                );

                this.$nextTick(() => {
                    if (this.isProcessable)
                        this.gridInstance.editRow(this.currentRowIndex);
                });
            }
        },
        nextRow() {
            if (!this.isLastRecord) {
                window.scrollBy(0, 34);
                if (this.currentRowIndex === this.pageSize - 1) {
                    this.currentPage += 1;
                } else {
                    this.currentRowIndex += 1;
                }
            } else {
                this.currentPage = 0;
                this.currentRowIndex = 0;
            }
            this.gridInstance.editRow(this.currentRowIndex);
        },
        save(status) {
            this.status = status;
            this.gridInstance.saveEditData();
        },
        close() {
            this.gridInstance.cancelEditData();
        },
        // set currentRowIndex for 0 because this line always opens first in DataGrid
        addRow() {
            this.currentRowIndex = 0;
            this.gridInstance.addRow();
        },
        showColumnChooser() {
            this.gridInstance.showColumnChooser();
        },
        // exequtes before saveEditData of DxDataGrid by listening @saving
        onSaving(e) {
            if (this.editorMode === "form") {
                if (this.isProcessable) {
                    this.emitUpdateStatus(this.status);
                } else {
                    this.emitSaveOrUpdate();
                }
            } else {
                let updatedRecords = [];
                e.changes.forEach((el) => {
                    this.gridInstance
                        .byKey(el.key)
                        .done((record) => updatedRecords.push(record));
                });
                this.emitBatchUpdate(updatedRecords);
            }
            this.updateUnsyncChanges({
                table: this.unsyncChangesTable,
                value: true
            });
        },
        emitUpdateStatus(status) {
            this.$emit(
                "updateStatus",
                this.gridInstance.getVisibleRows()[this.currentRowIndex].data,
                status
            );
        },
        // gets model for saving or updating by local variable currentRowIndex
        emitSaveOrUpdate() {
            this.$emit(
                "saveOrUpdate",
                this.gridInstance.getVisibleRows()[this.currentRowIndex].data
            );
        },
        emitBatchUpdate(records) {
            this.$emit("batchUpdate", records);
        },
        sync() {
            this.$emit("sync", {
                hasEditData: this.gridInstance.hasEditData(),
                updateUnsyncChanges: () =>
                    this.updateUnsyncChanges({
                        table: this.unsyncChangesTable,
                        value: false
                    })
            });
        },
        emitMultipleUpdateStatus() {
            this.$emit("multipleUpdateStatus");
        },
        gridReload() {
            this.gridInstance.getDataSource().reload();
        },
        saveChanges() {
            this.gridInstance.saveEditData();
            this.hasChanges = false;
        },
        //Code snippet from devexpress support
        overrideOnValueChanged(e) {
            var self = this;
            if (e.parentType === "dataRow") {
                const defaultValueChangeHandler =
                    e.editorOptions.onValueChanged;
                e.editorOptions.onValueChanged = function (args) {
                    if (self.isBatchMode) self.hasChanges = true;
                    defaultValueChangeHandler(args);
                };
            }
        },
        showRecipientsPopup() {
            this.$emit("showRecipientsPopup");
        }
    }
};
</script>

<style src="./GenericDataGrid.css"></style>
