<template src="./Vehicles.html"></template>

<script>
import { mapGetters, mapActions } from "vuex";
import DataGridHeader from "@/components/DataGridHeader";
import GenericDataGrid from "@/components/GenericDataGrid";
import PageLeavePopup from "@/components/PageLeavePopup";
import UnsavedChangesPopup from "@/components/UnsavedChangesPopup";
import CustomStore from "devextreme/data/custom_store";
import { store } from "@/store/index";
import notify from "devextreme/ui/notify";
import unsyncChangesTables from "@/constants/unsyncChangesTables";
import { generateParams } from "@/utils/params";
import ConfirmationPopup from "@/components/ConfirmationPopup";

const customStore = new CustomStore({
    key: "taLpsVehicleId",
    async load(loadOptions) {
        await store.dispatch(
            "vehicles/fetchVehicles",
            generateParams(loadOptions)
        );
        await store.dispatch("vehicles/fetchUnprocessedVehiclesCount");

        return store.getters["vehicles/getVehicles"];
    },
    totalCount() {
        return store.getters["vehicles/getTotalCount"];
    },
    insert() {},
    update() {}
});

export default {
    components: {
        DataGridHeader,
        GenericDataGrid,
        PageLeavePopup,
        UnsavedChangesPopup,
        ConfirmationPopup
    },
    name: "Vehicles",
    data() {
        return {
            showDialog: false,
            showUnsavedChangesDialog: false,
            to: null,
            isBatchMode: false,
            isProcessable: false,
            dataSource: customStore,
            defaultValues: {},
            defaultColumns: [
                "Marke",
                "Serie",
                "Karosserie",
                "Modell",
                "Lps Level",
                "Status Nr.",
                "Aktiv",
                "Start",
                "Freigabe",
                "Karosserie"
            ],
            showConfirmationFreigabeParkendeDialog: false
        };
    },
    computed: {
        ...mapGetters("vehicles", ["getVehicles"]),
        ...mapGetters("unsyncChanges", ["hasUnsyncChanges"]),
        gridColumns() {
            return [
                {
                    title: "ID",
                    dataField: "lpsVehicleId",
                    blockSorting: true,
                    blockEditing: true,
                    width: 70,
                    filterOperations: ["startswith"]
                },
                {
                    title: "Marke",
                    dataField: "lpsBrand",
                    blockSorting: true,
                    blockFiltering: true,
                    width: 70,
                    editorOptions: { readOnly: true }
                },
                {
                    title: "Serie",
                    dataField: "lpsSeries",
                    blockSorting: true,
                    blockFiltering: true,
                    width: 70,
                    editorOptions: { readOnly: true }
                },
                {
                    title: "Karosserie",
                    dataField: "lpsBodyType",
                    blockSorting: true,
                    blockFiltering: true,
                    width: 90,
                    editorOptions: { readOnly: true }
                },
                {
                    title: "Modell",
                    dataField: "lpsModel",
                    blockSorting: true,
                    blockFiltering: true,
                    editorOptions: { readOnly: true }
                },
                {
                    title: "Klasse",
                    dataField: "lpsVehicleClass",
                    blockFiltering: true,
                    width: 60,
                    lookup: {
                        dataSource: [
                            { id: "UKL", name: "UKL" },
                            { id: "KKL", name: "KKL" },
                            { id: "MKL", name: "MKL" },
                            { id: "GKL", name: "GKL" }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                },
                {
                    title: "Lps Level",
                    dataField: "vehicleRuleLevel",
                    required: true,
                    blockSorting: true,
                    width: 200,
                    lookup: {
                        dataSource: [
                            { id: -1, name: "Rot | Requests ablehnen" },
                            { id: -2, name: "Gelb | Manuelle Prüfung" },
                            { id: 0, name: "Grün | Okay, ggf. Parken" }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                },
                {
                    title: "Status Nr.",
                    dataField: "vehicleStatusNbr",
                    width: 85,
                    required: true,
                    blockFiltering: true,
                    lookup: {
                        dataSource: [
                            { id: 0, name: 0 },
                            { id: 1006, name: 1006 },
                            { id: 1008, name: 1008 }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                },
                this.fzgColumn,
                this.aktivColumn,
                {
                    title: "Start",
                    dataField: "vehicleStartDt",
                    dataType: "datetime",
                    width: 90,
                    required: true,
                    blockFiltering: true,
                    format: "dd.MM.yyyy",
                    editorOptions: { displayFormat: "dd.MM.yyyy", type: "date" }
                },
                {
                    title: "Freigabe",
                    dataField: "vehicleApproved",
                    blockSorting: true,
                    blockFiltering: true,
                    width: 90,
                    lookup: {
                        dataSource: [
                            { id: false, name: "Offen" },
                            { id: true, name: "Freigabe" }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                },
                {
                    title: "Letzter Bearbeiter",
                    dataField: "vehicleUser",
                    blockSorting: true,
                    blockFiltering: true,
                    width: 140,
                    editorOptions: { readOnly: true }
                },
                {
                    title: "Letzte Änderung",
                    dataField: "vehicleTs",
                    dataType: "datetime",
                    blockSorting: true,
                    blockFiltering: true,
                    format: "dd.MM.yyyy HH:mm",
                    width: 150,
                    editorOptions: {
                        readOnly: true,
                        displayFormat: "dd.MM.yyyy HH:mm",
                        type: "datetime"
                    }
                }
            ];
        },
        formStructure() {
            return [
                {
                    title: "",
                    items: [
                        {
                            label: "Marke",
                            name: "lpsBrand",
                            span: 2
                        },
                        {
                            label: "Serie",
                            name: "lpsSeries",
                            span: 2
                        },
                        {
                            label: "Modell",
                            name: "lpsModel",
                            span: 5
                        },
                        {
                            label: "Karosserie",
                            name: "lpsBodyType",
                            span: 2
                        },
                        {
                            label: "Klasse",
                            name: "lpsVehicleClass",
                            span: 2
                        },
                        {
                            name: "mCarFg",
                            span: 2,
                            hideLabel: true,
                            editorType: "dxSwitch",
                            editorOptions: {
                                switchedOffText: "Kein M",
                                switchedOnText: "M Fzg",
                                width: "70"
                            }
                        },
                        {
                            label: "Start",
                            name: "vehicleStartDt",
                            span: 3
                        },
                        {
                            name: "vehicleActive",
                            span: 2,
                            hideLabel: true,
                            editorType: "dxSwitch",
                            editorOptions: {
                                switchedOffText: "Inaktiv",
                                switchedOnText: "Aktiv",
                                width: "70"
                            }
                        },
                        {
                            label: "Lps Level",
                            hide: this.isProcessable,
                            name: "vehicleRuleLevel",
                            span: 4
                        },
                        {
                            label: "Status Nr.",
                            hide: this.isProcessable,
                            name: "vehicleStatusNbr",
                            span: 2
                        },
                        {
                            name: "vehicleApproved",
                            span: 2,
                            hide: this.isProcessable,
                            hideLabel: true,
                            editorType: "dxSwitch",
                            editorOptions: {
                                switchedOffText: "Offen",
                                switchedOnText: "Freigabe",
                                width: "70"
                            }
                        }
                    ]
                }
            ];
        },
        unsyncChangesVehicles() {
            return unsyncChangesTables.VEHICLES;
        },
        hasUnsyncChangesVehicles() {
            return this.hasUnsyncChanges(this.unsyncChangesVehicles);
        },
        fzgColumn() {
            if (this.isBatchMode)
                return {
                    title: "M Fzg",
                    dataField: "mCarFg",
                    blockSorting: true,
                    blockFiltering: true,
                    editorType: "dxSwitch",
                    width: 70
                };
            else
                return {
                    title: "M Fzg",
                    dataField: "mCarFg",
                    editorType: "dxLookup",
                    blockSorting: true,
                    blockFiltering: true,
                    width: 70,
                    lookup: {
                        dataSource: [
                            { id: false, name: "Kein M" },
                            { id: true, name: "M Fzg" }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                };
        },
        aktivColumn() {
            if (this.isBatchMode)
                return {
                    title: "Aktiv",
                    dataField: "vehicleActive",
                    blockSorting: true,
                    editorType: "dxSwitch",
                    width: 70,
                    trueText: "Aktiv",
                    falseText: "Inaktiv"
                };
            else
                return {
                    title: "Aktiv",
                    dataField: "vehicleActive",
                    editorType: "dxLookup",
                    blockSorting: true,
                    width: 90,
                    lookup: {
                        dataSource: [
                            { id: false, name: "Inaktiv" },
                            { id: true, name: "Aktiv" }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                };
        }
    },
    mounted() {
        if (this.getVehicles.length > 0) {
            const keys = Object.keys(this.getVehicles[0]);
            keys.forEach((key) => {
                if (!this.gridColumns.find((x) => x.dataField === key)) {
                    this.gridColumns.push({
                        title: key,
                        dataField: key,
                        hideInColumnChooser: true
                    });
                }
            });
        }
    },
    beforeRouteLeave(to, _, next) {
        if (to.path == "/login") {
            next();
        } else if (this.hasUnsyncChangesVehicles) {
            this.to = to;
            this.showDialog = true;
        } else {
            next();
        }
    },
    methods: {
        ...mapActions("vehicles", [
            "fetchVehicles",
            "updateVehicleStatus",
            "updateVehicle",
            "updateVehicles",
            "syncVehicles",
            "multipleVehiclesForwarding"
        ]),
        updateIsBatchMode(isBatchMode) {
            this.$nextTick(() => (this.isBatchMode = isBatchMode));
        },
        isRecordProcessiable(record) {
            return (
                record.vehicleStatusNbr == 0 && record.vehicleApproved == false
            );
        },
        updateProcessiable(record) {
            this.isProcessable = this.isRecordProcessiable(record);
        },
        async updateStatus(vehicle, status) {
            await this.updateVehicleStatus({
                model: vehicle,
                status
            });
            this.$refs["data-grid-component"].gridReload();
        },
        async update(model) {
            await this.updateVehicle(model);
            this.$refs["data-grid-component"].gridReload();
        },
        async batchUpdate(models) {
            await this.updateVehicles(models);
            this.$refs["data-grid-component"].gridReload();
        },
        async onSync(payload) {
            if (payload.hasEditData) {
                this.showUnsavedChangesDialog = true;
            } else {
                this.synchronize(payload.updateUnsyncChanges);
            }
        },
        synchronize(updateUnsyncChanges) {
            this.syncVehicles()
                .then(() => {
                    updateUnsyncChanges();
                    notify(
                        {
                            message: "Alle Änderungen synchronisiert",
                            position: {
                                my: "center top",
                                at: "center top"
                            }
                        },
                        "success",
                        3000
                    );
                })
                .catch((error) =>
                    notify(
                        {
                            message: error,
                            position: {
                                my: "center top",
                                at: "center top"
                            }
                        },
                        "error",
                        3000
                    )
                )
                .finally(() => {
                    this.$refs["data-grid-component"].gridReload();
                });
        },
        multipleUpdateStatus() {
            this.showConfirmationFreigabeParkendeDialog = true;
        },
        async handleConfirmFreigabeParkende() {
            await this.multipleVehiclesForwarding();
            this.$refs["data-grid-component"].gridReload();
        }
    }
};
</script>

<style src="./Vehicles.css"></style>
