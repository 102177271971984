import {
    sync,
    fetchByPage,
    fetchUnprocessedCount,
    update,
    updateStatus,
    multipleUpdate
} from "@/utils/api/dealers.js";

const state = {
    dealers: [],
    unprocessedDealersCount: null,
    totalCount: null
};

const getters = {
    getDealers: (state) => {
        return state.dealers;
    },
    getUnprocessedDealersCount: (state) => {
        return state.unprocessedDealersCount;
    },
    getTotalCount: (state) => {
        return state.totalCount;
    }
};

const mutations = {
    SET_DEALERS: (state, data) => {
        state.dealers = data.data.dealers;
        state.totalCount = data.data.totalCount;
    },
    SET_UNPROCESSED_DEALERS_COUNT: (state, data) => {
        state.unprocessedDealersCount = data;
    }
};

const actions = {
    fetchDealers({ commit }, query) {
        return new Promise((resolve, reject) => {
            fetchByPage(query)
                .then((response) => {
                    commit("SET_DEALERS", response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    fetchUnprocessedDealersCount({ commit }) {
        return new Promise((resolve, reject) => {
            fetchUnprocessedCount()
                .then((response) => {
                    commit("SET_UNPROCESSED_DEALERS_COUNT", response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateDealerStatus(_, data) {
        return new Promise((resolve, reject) => {
            updateStatus(data)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateDealer(_, data) {
        return new Promise((resolve, reject) => {
            update(data)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateDealers(_, data) {
        return new Promise((resolve, reject) => {
            multipleUpdate(data)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    syncDealers() {
        return new Promise((resolve, reject) => {
            sync()
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
