<template src="./AppFooter.html"></template>

<script>
export default {
    data() {
        return {
            version: "1.0"
        }
    },
    computed: {
        currentYear() {
            return new Date().getFullYear();
        }
    }
};
</script>

<style src="./AppFooter.css" scoped></style>
