import {
    fetchByPage,
    updateStatus
} from "@/utils/api/workData.js";

const state = {
    workData: [],
    totalCount: null
};

const getters = {
    getWorkDataByPage: (state) => {
        return state.workData;
    },
    getTotalCount: (state) => {
        return state.totalCount;
    }
};

const mutations = {
    SET_WORK_DATA: (state, data) => {
        state.workData = data.data.workData;
        state.totalCount = data.data.totalCount;
    }
};

const actions = {
    fetchManualChecks({ commit }, query) {
        return new Promise((resolve, reject) => {
            fetchByPage(query)
                .then((response) => {
                    commit("SET_WORK_DATA", response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateManualCheckStatus(_, data) {
        return new Promise((resolve, reject) => {
            updateStatus(data)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
