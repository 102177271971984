import { store } from "@/store";

function hasClaim(claim, claimValue) {
    const session = store.getters["account/getSession"];
    if (session?.user?.claims) {
        return session.user.claims[claim]?.find(cv => cv === claimValue) !== undefined;
    }

    return false;
}

function canManageUsers() {
    return hasClaim("BMW.LeadPreSelect.UserManage", "*");
}

function canUpdateScores() {
    return hasClaim("BMW.LeadPreSelect.UpdateScores", "ALLOWED");
}


export default {
    canManageUsers,
    canUpdateScores
}