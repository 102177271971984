<template src="./SideMenu.html"></template>

<script>
import { mapGetters, mapActions } from "vuex";
import SideMenuItem from "@/components/SideMenuItem";
import SideMenuTitle from "@/components/SideMenuTitle";
import permissionUtils from "@/utils/permissionUtils";

export default {
    components: {
        SideMenuItem,
        SideMenuTitle
    },
    computed: {
        ...mapGetters({
            manualCheckCases: "workData/getTotalCount",
            unprocessedDealersCases: "dealers/getUnprocessedDealersCount",
            unprocessedCampaignsCases: "campaigns/getUnprocessedCampaignsCount",
            unprocessedVehiclesCases: "vehicles/getUnprocessedVehiclesCount",
        }),
        items() {
            return [
                {
                    title: "MONITORING",
                    to: "/",
                    show: true
                },
                {
                    title: "DATENPFLEGE",
                    items: [
                        {
                            title: "Gelbfälle",
                            icon: "checkbox-marked-circle-plus-outline",
                            to: "/manual-checks",
                            newCases: this.manualCheckCases
                        },
                        {
                            title: "Kampagnen",
                            icon: "domain",
                            to: "/campaigns",
                            newCases: this.unprocessedCampaignsCases
                        },
                        {
                            title: "Fahrzeuge",
                            icon: "car",
                            to: "/vehicles",
                            newCases: this.unprocessedVehiclesCases
                        },
                        {
                            title: "Händler",
                            icon: "handshake-outline",
                            to: "/dealers",
                            newCases: this.unprocessedDealersCases
                        },
                        {
                            title: "Wunschhändler",
                            icon: "shopping-outline",
                            to: "/preferred-dealers"
                        }
                    ],
                    show: true
                },
                {
                    title: "STEUERUNG",
                    items: [
                        {
                            title: "Regelwerk",
                            icon: "list-status",
                            to: "/rules"
                        },
                        {
                            title: "Scorestufen",
                            icon: "counter",
                            to: "/score-steps"
                        },
                        {
                            title: "Statusnummern",
                            icon: "clipboard-pulse-outline",
                            to: "/status-numbers"
                        },
                        {
                            title: "Blacklisten",
                            icon: "playlist-remove",
                            to: "/blacklists"
                        }
                    ],
                    show: true
                },
                {
                    title: "VERWALTUNG",
                    items: [
                        {
                            title: "Benutzer",
                            icon: "account-cog",
                            to: "/users",
                            show: permissionUtils.canManageUsers()
                        }
                    ],
                    show: permissionUtils.canManageUsers()
                }
            ];
        }
    },
    mounted() {
        this.fetchManualChecks({ skip: 0, take: 1 });
        this.fetchUnprocessedDealersCount();
        this.fetchUnprocessedCampaignsCount();
        this.fetchUnprocessedVehiclesCount();
    },
    methods: {
        ...mapActions({
            fetchManualChecks: "workData/fetchManualChecks",
            fetchUnprocessedDealersCount:
                "dealers/fetchUnprocessedDealersCount",
            fetchUnprocessedCampaignsCount:
                "campaigns/fetchUnprocessedCampaignsCount",
            fetchUnprocessedVehiclesCount:
                "vehicles/fetchUnprocessedVehiclesCount"
        })
    }
};
</script>

<style src="./SideMenu.css" scoped></style>
