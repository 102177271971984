<template src="./CreateUser.html">
</template>

<script lang="js">
import { DxButton } from "devextreme-vue/button";
import { userModule } from "@/store/modules/users";
import { roleModule } from "@/store/modules/roles";
import { accountModule } from "@/store/modules/account";
import { leaveConfirmation } from "@/utils/leaveConfirmation";
import Errors from "./BackendResponseErrors";
import notify from "devextreme/ui/notify";
import { DxTextBox } from "devextreme-vue/text-box";
import { DxSelectBox } from "devextreme-vue/select-box";
import {
    DxValidator,
    DxRequiredRule,
    DxStringLengthRule,
    DxEmailRule,
    DxPatternRule
} from "devextreme-vue/validator";
import { DxTagBox } from "devextreme-vue/tag-box";
import DxTabPanel, { DxItem } from "devextreme-vue/tab-panel";
import { DxDateBox } from 'devextreme-vue/date-box';

export default {
    components: {
        DxButton,
        DxTextBox,
        DxSelectBox,
        DxValidator,
        DxRequiredRule,
        DxStringLengthRule,
        DxEmailRule,
        DxTagBox,
        DxTabPanel,
        DxItem,
        DxDateBox,
        DxPatternRule
    },
    data() {
        return {
            passwordOptions: {
                autocomplete: "new-password"
            },
            passwordHelp:
                "Das Passwort muss zwischen 6 und 40 Zeichen lang sein, aus römischen Buchstaben, "
                + "Zahlen und folgenden Sonderzeichen bestehen: "
                + "! \" $ % & / ( ) = ? ~ { [ ] } \\ @ + - _ # ' < > | , ; . :",
            passwordRequiredPattern:
                "^(?=.*[\"!$%&\\/()=?~{\\[\\]}\\\\@+\\-_#'<>|,;.:])(?=.*[a-zA-Z])(?=.*\\d).*$",
            passwordAllowedPattern:
                "^[A-Za-z\\d!\"$%&\\/()=?~{\\[\\]}\\\\@+-_#'<>|,;.:]*$",
            leaveConfirmationEnabled: true
        };
    },
    computed: {
        ...userModule.mapGetters({
            user: "getUser",
            isEdited: "isEdited"
        }),
        ...roleModule.mapGetters({
            roles: "getRoles"
        }),
        ...accountModule.mapGetters({
            session: "getSession"
        }),
        firstName: {
            get: function () {
                return this.user?.firstName ?? "";
            },
            set: function (value) {
                this.updateUser({ firstName: value });
            }
        },
        lastName: {
            get: function () {
                return this.user?.lastName ?? "";
            },
            set: function (value) {
                this.updateUser({ lastName: value });
            }
        },
        shortName: {
            get: function () {
                return this.user?.shortName ?? "";
            },
            set: function (value) {
                this.updateUser({ shortName: value });
            }
        },
        email: {
            get: function () {
                return this.user?.email ?? "";
            },
            set: function (value) {
                this.updateUser({ email: value });
            }
        },
        password: {
            get: function () {
                return this.user?.password ?? "";
            },
            set: function (value) {
                this.updateUser({ password: value });
            }
        },
        startedDate: {
            get: function () {
                return this.user?.startedDate ?? new Date();
            },
            set: function (value) {
                this.updateUser({ startedDate: value });
            }
        },
        role: {
            get: function () {
                return this.user?.role ?? "";
            },
            set: function (value) {
                this.updateUser({ role: value });
            }
        },
        active: {
            get: function () {
                return this.user?.active ?? true;
            },
            set: function (value) {
                this.updateUser({ active: value });
            }
        }
    },
    methods: {
        ...userModule.mapActions({
            addNewUser: "addNewUser",
            commitCreateUser: "createUser"
        }),
        ...userModule.mapGetters({
            getErrors: "getErrors"
        }),
        ...userModule.mapMutations({
            setErrors: "setErrors",
            updateUser: "updateUser"
        }),
        async createUser() {
            await this.commitCreateUser();
            const errors = this.getErrors();
            this.setErrors(null);

            if (errors !== undefined && errors !== null) {
                const validationErrors = errors.filter((e) =>
                    e.includes(Errors.ValidationProblem.Error)
                );

                if (
                    errors.find((e) => e.includes(Errors.DuplicateEmail.Error)) !==
                    undefined
                ) {
                    notify(Errors.DuplicateEmail.Message, "error", 10000);
                } else if (validationErrors !== undefined) {
                    notify(
                        validationErrors.map((error) => {
                            return error.substr(error.search("message:") + 8);
                        }),
                        "error",
                        10000
                    );
                } else {
                    notify(Errors.Default.Message, "error", 10000);
                }
            } else {
                this.leaveConfirmationEnabled = false;
                this.$router.push("/users");
            }
        },
        backButtonClick() {
            this.$router.push("/users");
        }
    },
    created() {
        this.addNewUser();
        window.onbeforeunload = () => this.isEdited;
    },
    beforeDestroy() {
        window.onbeforeunload = null;
    },
    beforeRouteLeave(_to, _from, next) {
        leaveConfirmation(this.isEdited && this.leaveConfirmationEnabled, next);
    }
};
</script>

<style src="./CreateUser.css" lang="css" scoped></style>