<template src="./SideMenuItem.html"></template>

<script>
import AppIcon from "@/components/AppIcon";

export default {
    components: {
        AppIcon
    },
    props: {
        icon: {
            type: String,
            required: false
        },
        title: {
            type: String,
            required: true
        },
        to: {
            type: String,
            required: false
        },
        newCases: {
            type: Number,
            required: false
        },
        show: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        classes() {
            const path = this.$route.fullPath;
            return ["side-menu-link", path === this.to ? "active" : null];
        },
        newCasesDisplay() {
            if (this.newCases > 0) return "(" + this.newCases + ")";
            return "";
        }
    }
};
</script>

<style src="./SideMenuItem.css" scoped></style>
