import service from "./api.js";

export function fetch() {
    return service({
        url: "/preferred-dealers",
        method: "get"
    });
}

export function decontrol(data) {
    return service({
        url: "/preferred-dealers/decontrol",
        method: "post",
        data
    });
}