<template src="./ScoreSteps.html"></template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing
} from "devextreme-vue/data-grid";
import { DxBox, DxItem } from "devextreme-vue/box";
import { DxLoadPanel, DxPosition } from "devextreme-vue/load-panel";
import DataGridHeader from "@/components/DataGridHeader";
import DxButton from "devextreme-vue/button";
import PageLeavePopup from "@/components/PageLeavePopup/PageLeavePopup.vue";
import notify from "devextreme/ui/notify";
import permissionUtils from "@/utils/permissionUtils";
import unsyncChangesTables from "@/constants/unsyncChangesTables";

export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        PageLeavePopup,
        DxBox,
        DxItem,
        DxLoadPanel,
        DxPosition,
        DxButton,
        DataGridHeader
    },
    name: "ScoreSteps",
    data() {
        return {
            to: null,
            showDialog: false,
            canUpdate: false
        };
    },
    computed: {
        ...mapGetters("scoreSteps", ["getScoreStepTables"]),
        ...mapGetters("unsyncChanges", ["hasUnsyncChanges"]),
        ...mapGetters("account", ["getSession"]),
        unsyncChangesScoreSteps() {
            return unsyncChangesTables.SCORE_STEPS;
        },
        hasUnsyncChangesScoreSteps() {
            return this.hasUnsyncChanges(this.unsyncChangesScoreSteps);
        },
        syncButtonColor() {
            if (this.hasUnsyncChangesScoreSteps) {
                return "default";
            }
            return "primary";
        },
        displaySyncButton() {
            return (
                this.getSession.user.role == "Super User" ||
                this.getSession.user.role == "Admin"
            );
        }
    },
    mounted() {
        this.fetchScoreSteps().then(() => this.setActiveRows());
        this.canUpdate = permissionUtils.canUpdateScores();
    },
    beforeRouteLeave(to, _, next) {
        if (to.path == "/login") {
            next();
        } else if (this.hasUnsyncChangesScoreSteps) {
            this.to = to;
            this.showDialog = true;
        } else {
            next();
        }
    },
    methods: {
        ...mapActions("scoreSteps", [
            "fetchScoreSteps",
            "updateActiveScoreStep",
            "syncScoreSteps"
        ]),
        ...mapActions("unsyncChanges", ["updateUnsyncChanges"]),
        setActiveRows() {
            this.getScoreStepTables.forEach((table) => {
                this.getGridInstance(table.title).on("contentReady", () => {
                    this.getGridInstance(table.title).selectRowsByIndexes([
                        table.scoreSteps.findIndex((step) => {
                            return step.leadFlowControlActive === true;
                        })
                    ]);
                    this.getGridInstance(table.title).off("contentReady");
                });
            });
        },
        getGridInstance(brand) {
            return this.$refs[this.deleteSpaces(brand)][0].instance;
        },
        deleteSpaces(string) {
            return string.replace(/ /g, "");
        },
        latestUpdateInfo(brand) {
            let lastUpdatedRecord = this.getScoreStepTables
                .find((el) => el.title === brand)
                .scoreSteps.slice()
                .sort(function (a, b) {
                    return (
                        new Date(b.leadFlowControlTs) -
                        new Date(a.leadFlowControlTs)
                    );
                })[0];

            let date = new Date(lastUpdatedRecord.leadFlowControlTs);

            let DD = ("0" + date.getDate()).slice(-2);
            let MM = ("0" + (date.getMonth() + 1)).slice(-2);
            let YYYY = date.getFullYear();

            let hh = ("0" + date.getHours()).slice(-2);
            let mm = ("0" + date.getMinutes()).slice(-2);

            let dateString =
                DD + "." + MM + "." + YYYY + " " + hh + ":" + mm + " Uhr";

            return {
                user: lastUpdatedRecord.leadFlowControlUser,
                date: dateString
            };
        },
        onRowChanged(e) {
            if (e?.selectedRowsData[0]?.leadFlowControlActive === false) {
                this.getGridInstance(
                    e.selectedRowsData[0].brand + "Loader"
                ).show();
                this.updateActiveScoreStep({
                    key: e.selectedRowKeys[0]
                }).then(() => {
                    this.fetchScoreSteps().then(() =>
                        this.getGridInstance(
                            e.selectedRowsData[0].brand + "Loader"
                        ).hide()
                    );
                    this.updateUnsyncChanges({
                        table: unsyncChangesTables.SCORE_STEPS,
                        value: true
                    });
                });
            }
        },
        async synchronize() {
            this.syncScoreSteps()
                .then(() => {
                    this.updateUnsyncChanges({
                        table: unsyncChangesTables.SCORE_STEPS,
                        value: false
                    });
                    notify(
                        {
                            message: "Alle Änderungen synchronisiert",
                            position: {
                                my: "center top",
                                at: "center top"
                            }
                        },
                        "success",
                        3000
                    );
                })
                .catch((error) =>
                    notify(
                        {
                            message: error,
                            position: {
                                my: "center top",
                                at: "center top"
                            }
                        },
                        "error",
                        3000
                    )
                )
                .finally(() => {
                    this.fetchScoreSteps().then(() => this.setActiveRows());
                });
        }
    }
};
</script>

<style src="./ScoreSteps.css"></style>
