import service from "./api.js";

export function fetchByPage(data) {
    return service({
        url: "/blacklist-dialing-codes",
        method: "post",
        data
    });
}

export function saveOrUpdate(data) {
    return service({
        url: "/blacklist-dialing-codes/save-or-update",
        method: "post",
        data
    });
}

export function sync() {
    return service({
        url: "/blacklist-dialing-codes/sync",
        method: "get"
    });
}