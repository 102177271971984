<template>
    <div id="app">
        <component :is="layout" v-if="shouldShow()">
            <router-view />
        </component>
    </div>
</template>

<script>
import MainLayout from "@/layouts/MainLayout/MainLayout.vue";
import AuthLayout from "@/layouts/AuthLayout";
import { store } from "@/store";
import { locale } from "devextreme/localization";

export default {
    name: "app",
    components: {
        MainLayout,
        AuthLayout
    },
    data: function () {
        return {
            loginValidationPending: true
        };
    },
    created() {
        locale("de");
    },
    computed: {
        layout() {
            return this.$route?.meta?.layout ?? "MainLayout";
        }
    },
    methods: {
        shouldShow() {
            return !this.loginValidationPending;
        }
    },
    mounted() {
        // If the user is on an internal page, validate the login and redirect to the login page if no user is logged in.
        this.loginValidationPending = true;
        if (store.getters["account/getSession"] === null) {
            store.dispatch("account/verifyLogin").then(() => {
                if (
                    store.getters["account/getSession"] == undefined &&
                    !this.$route.matched.some(
                        (record) => record.meta.allowAnonymous
                    )
                ) {
                    this.$router.push("/login");
                } else if (
                    store.getters["account/getSession"] !== null &&
                    this.$route.name === "login"
                ) {
                    this.$router.push("/");
                }
                this.loginValidationPending = false;
            });
        } else {
            this.loginValidationPending = false;
        }
    }
};
</script>

<style></style>
