export default {
  DuplicateEmail: {
    Error: "code: DuplicateEmail",
    Message: "A user with that email address already exists."
  },
  NetworkError: {
    Error: "Network Error",
    Message: "Failed to connect to backend."
  },
  ValidationProblem: {
    Error: "validation-problem -> property:"
  },
  Default: {
    Message: "Unable to save user: Unknown error."
  }
}