<template src="./Blacklists.html"></template>

<script>
import DxTabs from "devextreme-vue/tabs";
import DataGridHeader from "@/components/DataGridHeader";
import BlacklistEmails from "@/components/Blacklists/BlacklistEmails";
import BlacklistNames from "@/components/Blacklists/BlacklistNames";
import BlacklistDialingCodes from "@/components/Blacklists/BlacklistDialingCodes";
import PageLeavePopup from "@/components/PageLeavePopup";
import unsyncChangesTables from "@/constants/unsyncChangesTables";
import { mapGetters, mapActions } from "vuex";

export default {
    components: {
        DxTabs,
        DataGridHeader,
        BlacklistEmails,
        BlacklistNames,
        BlacklistDialingCodes,
        PageLeavePopup
    },
    name: "Blacklists",
    data() {
        return {
            to: null,
            showDialog: false,
            selectedIndex: 0,
            tabs: [
                { text: "Email & Domain & Kunden", icon: "email" },
                { text: "Telefon & Vorwahlen", icon: "tel" },
                { text: "Vor- & Nachnamen", icon: "user" }
            ]
        };
    },
    beforeRouteLeave(to, _, next) {
        if (to.path == "/login") {
            next();
        } else if (this.hasAnyUnsyncChanges) {
            this.to = to;
            this.showDialog = true;
        } else {
            next();
        }
    },
    computed: {
        ...mapGetters("unsyncChanges", ["hasUnsyncChanges"]),
        isVisibleEmailsGrid() {
            return this.selectedIndex == 0;
        },
        isVisibleDialingCodesGrid() {
            return this.selectedIndex == 1;
        },
        isVisibleNamesGrid() {
            return this.selectedIndex == 2;
        },
        unsyncChangesDialingCodes() {
            return unsyncChangesTables.BLACKLIST_DIALING_CODES;
        },
        unsyncChangesEmails() {
            return unsyncChangesTables.BLACKLIST_EMAILS;
        },
        unsyncChangesNames() {
            return unsyncChangesTables.BLACKLIST_NAMES;
        },
        unsyncChangesBlacklists() {
            return [
                this.unsyncChangesDialingCodes,
                this.unsyncChangesEmails,
                this.unsyncChangesNames
            ];
        },
        hasAnyUnsyncChanges() {
            var array = [
                this.hasUnsyncChanges(this.unsyncChangesDialingCodes),
                this.hasUnsyncChanges(this.unsyncChangesEmails),
                this.hasUnsyncChanges(this.unsyncChangesNames)
            ];
            return array.some((el) => el === true);
        }
    },
    methods: {
        ...mapActions("blacklistDialingCodes", ["syncBlacklistDialingCodes"]),
        ...mapActions("blacklistEmails", ["syncBlacklistEmails"]),
        ...mapActions("blacklistNames", ["syncBlacklistNames"]),
        async synchronize(payload) {
            //This is case for sync after page leave and we need use methods from unmounted views
            if (payload.syncAll) {
                await this.syncBlacklistDialingCodes();
                await this.syncBlacklistEmails();
                await this.syncBlacklistNames();
                payload.updateUnsyncChanges();
            } else {
                if (this.isVisibleEmailsGrid)
                    this.$refs["blacklist-emails"].synchronize(payload);
                if (this.isVisibleDialingCodesGrid)
                    this.$refs["blacklist-dialing-codes"].synchronize(payload);
                if (this.isVisibleNamesGrid)
                    this.$refs["blacklist-names"].synchronize(payload);
            }
        }
    }
};
</script>
