import { fetch, decontrol } from "@/utils/api/preferredDealers.js";

const state = {
    preferredDealers: []
};

const getters = {
    getPreferredDealers: (state) => {
        return state.preferredDealers;
    }
};

const mutations = {
    SET_PREFERRRED_DEALERS: (state, data) => {
        state.preferredDealers = data;
    }
};

const actions = {
    fetchPreferredDealers({ commit }) {
        return new Promise((resolve, reject) => {
            fetch()
                .then((response) => {
                    commit("SET_PREFERRRED_DEALERS", response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    decontrolPreferredDealers(_, id) {
        return new Promise((resolve, reject) => {
            decontrol(id)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
