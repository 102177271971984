import {
    fetchByPage,
    saveOrUpdate,
    sync
} from "@/utils/api/blacklistDialingCodes.js";

const state = {
    blacklistDialingCodes: [],
    totalCount: null
};

const getters = {
    getBlacklistDialingCodes: (state) => {
        return state.blacklistDialingCodes;
    },
    getTotalCount: (state) => {
        return state.totalCount;
    }
};

const mutations = {
    SET_BLACKLIST_DIALING_CODES: (state, data) => {
        state.blacklistDialingCodes = data.data.blacklistDialingCodes;
        state.totalCount = data.data.totalCount;    }
};

const actions = {
    fetchBlacklistDialingCodes({ commit }, query) {
        return new Promise((resolve, reject) => {
            fetchByPage(query)
                .then((response) => {
                    commit("SET_BLACKLIST_DIALING_CODES", response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    saveOrUpdateBlacklistDialingCode(_, data) {
        return new Promise((resolve, reject) => {
            saveOrUpdate(data)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    syncBlacklistDialingCodes() {
        return new Promise((resolve, reject) => {
            sync()
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
