import service from "./api.js";

export function fetchByPage(query) {
    return service({
        url: "/work-data/manual-checks",
        method: "get",
        params: query
    });
}

export function updateStatus(data) {
    return service({
        url: "/work-data/update-status",
        method: "put",
        data
    });
}
