<template src="./AppHeader.html"></template>

<script>
import logoBMW from "@/assets/img/BMW_logo_(gray).svg";
import logoMINI from "@/assets/img/MINI_logo_48x48px.png";
import logoGKK from "@/assets/img/gkk_dialog_positiv.jpg";
import UserBadge from "@/components/UserBadge";

export default {
    components: {
        UserBadge
    },
    data() {
        return {
            logoBMW,
            logoMINI,
            logoGKK
        };
    }
}
</script>

<style src="./AppHeader.css" scoped></style>
