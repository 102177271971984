import { fetch } from "@/utils/api/processLogs.js";

const state = {
    processLogs: []
};

const getters = {
    getProcessLogs: (state) => {
        return state.processLogs;
    }
};

const mutations = {
    SET_PROCESS_LOGS: (state, data) => {
        state.processLogs = data;
    }
};

const actions = {
    fetchProcessLogs({ commit }) {
        return new Promise((resolve, reject) => {
            fetch()
                .then((response) => {
                    commit("SET_PROCESS_LOGS", response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
