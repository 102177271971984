<template src="./ManualChecks.html"></template>

<script>
import { mapGetters, mapActions } from "vuex";
import DataGridHeader from "@/components/DataGridHeader";
import GenericDataGrid from "@/components/GenericDataGrid";
import DataSource from "devextreme/data/data_source"
import { store } from "@/store/index";

const customStore = new DataSource({
    key: "lpsDataId",
    async load(loadOptions) {
        if (loadOptions.sort?.length > 0) {
            loadOptions.selector = loadOptions.sort[0].selector;
            loadOptions.desc = loadOptions.sort[0].desc;
        }
        await store.dispatch("workData/fetchManualChecks", loadOptions);
        return store.getters["workData/getWorkDataByPage"];
    },
    totalCount() {
        return store.getters["workData/getTotalCount"];
    },
    update() {}
});

export default {
    components: {
        DataGridHeader,
        GenericDataGrid
    },
    name: "ManualChecks",
    data() {
        return {
            dataSource: customStore,
            gridColumns: [
                {
                    title: "RequestID",
                    dataField: "contactHistoryOpportunityId",
                    blockFiltering: true,
                    blockSorting: true
                },
                {
                    title: "Anrede",
                    dataField: "salutation",
                    blockFiltering: true,
                    blockSorting: true,
                    width: 70,
                    editorOptions: { maxLength: 20 }
                },
                {
                    title: "Vorname",
                    dataField: "firstName",
                    blockFiltering: true,
                    blockSorting: true,
                    editorOptions: { maxLength: 60 }
                },
                {
                    title: "Nachname",
                    dataField: "lastName",
                    blockFiltering: true,
                    blockSorting: true,
                    editorOptions: { maxLength: 60 }
                },
                {
                    title: "Erstelldatum",
                    dataField: "tdSourceDt",
                    dataType: "datetime",
                    blockFiltering: true,
                    blockSorting: true,
                    width: 100,
                    format: "dd.MM.yyyy"
                },
                {
                    title: "Requesttyp",
                    dataField: "opportunityType",
                    blockFiltering: true,
                    lookup: {
                        dataSource: [
                            { id: 0, name: "Campaign" },
                            { id: 1, name: "Angebotsanfrage | RFO" },
                            { id: 3, name: "Probefahrtanfrage | TDA" },
                            {
                                id: 4,
                                name: "Informationsanfrage | RFI(D)"
                            },
                            { id: 5, name: "Car Configurator | VCO" }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                }
            ],
            defaultColumns: [
                "RequestID",
                "Anrede",
                "Vorname",
                "Nachname",
                "Erstelldatum",
                "Requesttyp"
            ],
            formStructure: [
                {
                    title: "Interessent",
                    items: [
                        {
                            label: "Anrede",
                            name: "salutation",
                            span: 2
                        },
                        {
                            label: "Titel",
                            name: "title",
                            span: 2,
                            editorOptions: { maxLength: 30 }
                        },
                        {
                            label: "Vorname",
                            name: "firstName"
                        },
                        {
                            label: "Nachname",
                            name: "lastName"
                        },
                        {
                            label: "Straße",
                            name: "address1",
                            span: 3,
                            editorOptions: { maxLength: 200 }
                        },
                        {
                            label: "Nr.",
                            name: "address2",
                            span: 1,
                            editorOptions: { maxLength: 10 }
                        },
                        {
                            label: "Wohnort",
                            name: "city",
                            span: 3,
                            editorOptions: { maxLength: 40 }
                        },
                        {
                            label: "PLZ",
                            name: "zipCode",
                            span: 1,
                            editorOptions: { maxLength: 20 }
                        },
                        {
                            label: "Alter",
                            name: "ageYears",
                            span: 2,
                            editorType: "dxNumberBox",
                            editorOptions: {
                                readOnly: true,
                                format: "#,##0.###"
                            }
                        },
                        {
                            label: "Firma",
                            name: "coFg",
                            span: 2,
                            editorType: "dxLookup",
                            editorOptions: {
                                readOnly: true,
                                dataSource: [
                                    { id: 0, name: "Nein" },
                                    { id: 1, name: "Ja" }
                                ],
                                valueExpr: "id",
                                displayExpr: "name"
                            }
                        }
                    ]
                },
                {
                    title: "Kontakt",
                    items: [
                        {
                            label: "Telefon (privat)",
                            name: "privPhone",
                            editorOptions: { maxLength: 20 }
                        },
                        {
                            label: "Mobil (privat)",
                            name: "privMobil",
                            editorOptions: { maxLength: 20 }
                        },
                        {
                            label: "eMail (privat)",
                            name: "privEmail",
                            editorOptions: { maxLength: 80 }
                        },
                        {
                            label: "Telefon (geschäftlich)",
                            name: "busPhone",
                            editorOptions: { maxLength: 20 }
                        },
                        {
                            label: "Mobil (geschäftlich)",
                            name: "busMobil",
                            editorOptions: { maxLength: 20 }
                        },
                        {
                            label: "eMail (geschäftlich)",
                            name: "busEmail",
                            editorOptions: { maxLength: 80 }
                        }
                    ]
                },
                {
                    title: "Interesse-Fahrzeug",
                    items: [
                        {
                            label: "Marke",
                            name: "intVehBrand",
                            editorOptions: { readOnly: true }
                        },
                        {
                            label: "Serie",
                            name: "intVehSeries",
                            editorOptions: { readOnly: true }
                        },
                        {
                            label: "Model",
                            name: "intVehModel",
                            editorOptions: { readOnly: true }
                        },
                        {
                            label: "Modeltyp",
                            name: "intVehModelTyp",
                            editorOptions: { readOnly: true }
                        },
                        {
                            label: "Baureihe",
                            name: "intVehConstSeries",
                            editorOptions: { readOnly: true }
                        },
                        {
                            label: "Karosserie",
                            name: "intVehBodyTyp",
                            span: 2,
                            editorOptions: { readOnly: true }
                        },
                        {
                            label: "WMZ",
                            name: "wmzMonths",
                            span: 2,
                            editorType: "dxNumberBox",
                            editorOptions: { readOnly: true, format: "#,##0.#" }
                        }
                    ]
                },
                {
                    title: "Request Information",
                    items: [
                        {
                            label: "Erstelldatum",
                            name: "tdSourceDt",
                            span: 2,
                            editorOptions: { readOnly: true }
                        },
                        {
                            label: "LPS-Ausnahme",
                            name: "lpsFg",
                            span: 2,
                            editorType: "dxLookup",
                            editorOptions: {
                                readOnly: true,
                                dataSource: [
                                    { id: 0, name: "Nein" },
                                    { id: 1, name: "Ja" }
                                ],
                                valueExpr: "id",
                                displayExpr: "name"
                            }
                        },
                        {
                            label: "Quelle",
                            name: "tdSource",
                            editorOptions: { readOnly: true }
                        },
                        {
                            label: "Requesttyp",
                            name: "opportunityType",
                            editorType: "dxLookup",
                            editorOptions: {
                                readOnly: true,
                                dataSource: [
                                    { id: 0, name: "Campaign" },
                                    { id: 1, name: "Angebotsanfrage | RFO" },
                                    { id: 3, name: "Probefahrtanfrage | TDA" },
                                    {
                                        id: 4,
                                        name: "Informationsanfrage | RFI(D)"
                                    },
                                    { id: 5, name: "Car Configurator | VCO" }
                                ],
                                valueExpr: "id",
                                displayExpr: "name"
                            }
                        },
                        {
                            label: "Leadkontext",
                            name: "leadContext",
                            editorOptions: { readOnly: true }
                        },
                        {
                            label: "Weiterleitungstext",
                            name: "opportunityDesc",
                            editorType: "dxTextArea",
                            editorOptions: { readOnly: true, height: "75px" }
                        }
                    ]
                },
                {
                    title: "Händler",
                    items: [
                        {
                            label: "Buno",
                            name: "dealerNbr",
                            span: 1,
                            editorOptions: { readOnly: true }
                        },
                        {
                            label: "Name",
                            name: "dealerName",
                            span: 3,
                            editorOptions: { readOnly: true }
                        },
                        {
                            label: "Ort",
                            name: "dealerCity",
                            editorOptions: { readOnly: true }
                        }
                    ]
                },
                {
                    title: "LPS Information",
                    items: [
                        {
                            label: "Folge",
                            name: "lpsRuleSequenceNbr",
                            span: 1,
                            editorType: "dxNumberBox",
                            editorOptions: { readOnly: true }
                        },
                        {
                            label: "Regel",
                            name: "ruleName",
                            span: 3,
                            editorOptions: { readOnly: true }
                        },

                        {
                            label: "Importdatum",
                            name: "downloadTs",
                            span: 2,
                            editorType: "dxDateBox",
                            editorOptions: {
                                readOnly: true,
                                displayFormat: "dd.MM.yyyy"
                            }
                        },
                        {
                            label: "LPS_DATA_ID",
                            name: "lpsDataId",
                            span: 2,
                            editorOptions: { readOnly: true }
                        }
                    ]
                },
                {
                    title: "Aktuelles Fahrzeug",
                    items: [
                        {
                            label: "Marke",
                            name: "vehBrand",
                            span: 2,
                            editorOptions: { readOnly: true }
                        },
                        {
                            label: "Baujahr",
                            name: "prodYear",
                            span: 2,
                            editorOptions: {
                                readOnly: true,
                                mask: "XXXX",
                                maskRules: { X: /[0-9]/ },
                                maskChar: ""
                            }
                        },
                        {
                            label: "Serie",
                            name: "vehSeries",
                            editorOptions: { readOnly: true }
                        }
                    ]
                },
                {
                    title: "BMW Identifikation",
                    items: [
                        {
                            label: "GCID",
                            name: "tdConNbr",
                            editorOptions: { readOnly: true }
                        },
                        {
                            label: "RequestID",
                            name: "contactHistoryOpportunityId",
                            editorOptions: { readOnly: true }
                        }
                    ]
                }
            ]
        };
    },
    computed: {
        ...mapGetters("workData", ["getWorkDataByPage"])
    },
    mounted() {
        this.fetchManualChecks({ skip: 0, take: 1 }).then(() => {
            if (this.getWorkDataByPage.length > 0) {
                const keys = Object.keys(this.getWorkDataByPage[0]);
                keys.forEach((key) => {
                    if (!this.gridColumns.find((x) => x.dataField === key)) {
                        this.gridColumns.push({
                            title: key,
                            dataField: key,
                            hideInColumnChooser: true
                        });
                    }
                });
            }
        });
    },
    methods: {
        ...mapActions("workData", [
            "fetchManualChecks",
            "updateManualCheckStatus"
        ]),
        async updateStatus(manualCheck, status) {
            await this.updateManualCheckStatus({
                model: manualCheck,
                status
            });
            this.$refs["data-grid-component"].gridReload();
        }
    }
};
</script>
