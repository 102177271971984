<template src="./Rules.html"></template>

<script>
import { mapGetters, mapActions } from "vuex";
import DataGridHeader from "@/components/DataGridHeader";
import GenericDataGrid from "@/components/GenericDataGrid";
import PageLeavePopup from "@/components/PageLeavePopup";
import UnsavedChangesPopup from "@/components/UnsavedChangesPopup";
import CustomStore from "devextreme/data/custom_store";
import { store } from "@/store/index";
import notify from "devextreme/ui/notify";
import unsyncChangesTables from "@/constants/unsyncChangesTables";

const customStore = new CustomStore({
    key: "taLpsRuleId",
    loadMode: "raw",
    async load() {
        await store.dispatch("rules/fetchRules");
        return store.getters["rules/getRules"];
    },
    insert() { },
    update() { }
});

export default {
    components: {
        DataGridHeader,
        GenericDataGrid,
        PageLeavePopup,
        UnsavedChangesPopup
    },
    name: "Rules",
    data() {
        return {
            showDialog: false,
            showUnsavedChangesDialog: false,
            to: null,
            isFirstLoad: true,
            dataSource: customStore,
            unsyncChangesTables,
            defaultValues: {
                ruleActiveFg: true,
                ruleStartDt: new Date(Date.now()).toISOString()
            },
            gridColumns: [
                {
                    title: "ID",
                    dataField: "lpsRuleId",
                    width: 60,
                    filterOperations: ["contains"]
                },
                {
                    title: "SQL",
                    dataField: "ruleSql",
                    visible: false,
                    hideInColumnChooser: true
                },
                {
                    title: "Reihenfolge",
                    dataField: "ruleSequenceNbr",
                    width: 60,
                    required: true,
                    filterOperations: ["contains"]
                },
                {
                    title: "Name",
                    dataField: "ruleName",
                    required: true,
                    editorOptions: { maxLength: 150 },
                    filterOperations: ["contains"]
                },
                {
                    title: "Aktiv",
                    dataField: "ruleActiveFg",
                    width: 90,
                    lookup: {
                        dataSource: [
                            { id: false, name: "Inaktiv" },
                            { id: true, name: "Aktiv" }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                },
                {
                    title: "Typ/ Reichweite",
                    dataField: "ruleType",
                    width: 210,
                    lookup: {
                        dataSource: [
                            { id: false, name: "Allgemein, inkl. Ausnahmen" },
                            { id: true, name: "Normal, exkl. Ausnahmen" }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                },
                {
                    title: "Start",
                    dataField: "ruleStartDt",
                    dataType: "datetime",
                    width: 145,
                    required: true,
                    format: "dd.MM.yyyy",
                    editorOptions: {
                        displayFormat: "dd.MM.yyyy",
                        type: "date"
                    }
                },
                {
                    title: "Level",
                    dataField: "ruleLevel",
                    required: true,
                    lookup: {
                        dataSource: [
                            { id: -1, name: "Rot | Requests ablehnen" },
                            { id: -2, name: "Gelb | Manuelle Prüfung" },
                            { id: 0, name: "Grün | Okay, aber Parken" }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                },
                {
                    title: "Letzter Bearbeiter",
                    dataField: "ruleUser",
                    width: 140,
                    blockEditing: true,
                    filterOperations: ["contains"]
                },
                {
                    title: "Letzte Änderung",
                    dataField: "ruleTs",
                    dataType: "datetime",
                    format: "dd.MM.yyyy HH:mm",
                    blockEditing: true,
                    width: 180,
                    editorOptions: {
                        displayFormat: "dd.MM.yyyy HH:mm",
                        type: "datetime"
                    }
                }
            ],
            defaultColumns: [
                "ID",
                "Name",
                "Aktiv",
                "Typ/ Reichweite",
                "Reihenfolge",
                "Start",
                "Level",
                "Bearbeiter",
                "Letzte Änderung"
            ],
            formStructure: [
                {
                    title: "",
                    items: [
                        {
                            label: "Reihenfolge",
                            name: "ruleSequenceNbr",
                            span: 1
                        },
                        {
                            label: "Name",
                            name: "ruleName",
                            span: 2
                        },
                        {
                            label: "Level",
                            name: "ruleLevel",
                            span: 2
                        },
                        {
                            name: "ruleType",
                            hideLabel: true,
                            span: 1,
                            editorType: "dxSwitch",
                            editorOptions: {
                                switchedOffText: "Allgemein",
                                switchedOnText: "Normal",
                                width: "70",
                                hint: "Allgemein, inkl. Ausnahmen / Normal, exkl. Ausnahmen"
                            }
                        },
                        {
                            label: "Start",
                            name: "ruleStartDt",
                            span: 1
                        },
                        {
                            name: "ruleActiveFg",
                            hideLabel: true,
                            span: 1,
                            editorType: "dxSwitch",
                            editorOptions: {
                                switchedOffText: "Inaktiv",
                                switchedOnText: "Aktiv",
                                width: "70"
                            }
                        },
                        {
                            label: "SQL",
                            name: "ruleSql",
                            span: 8,
                            required: true,
                            editorType: "dxTextArea",
                            editorOptions: {
                                minHeight: "275",
                                autoResizeEnabled: true,
                                hint:
                                    `Achtung:                  
Starte mit “SELECT “ und niemals mit Leerzeichen.
Verwende für Kommentare nur /* */ und niemals --.
Teste das Statement direkt auf der Datenbank und entferne,* vor dem speichern!`
                            },
                        }
                    ]
                }
            ]
        };
    },
    computed: {
        ...mapGetters("rules", ["getRules"]),
        ...mapGetters("unsyncChanges", ["hasUnsyncChanges"]),
        hasUnsyncChangesRules() {
            return this.hasUnsyncChanges(unsyncChangesTables.RULES);
        }
    },
    mounted() {
        if (this.getRules.length > 0) {
            const keys = Object.keys(this.getRules[0]);
            keys.forEach((key) => {
                if (!this.gridColumns.find((x) => x.dataField === key)) {
                    this.gridColumns.push({
                        title: key,
                        dataField: key,
                        hideInColumnChooser: true
                    });
                }
            });
        }
    },
    beforeRouteLeave(to, _, next) {
        if (to.path == "/login") {
            next();
        } else if (this.hasUnsyncChangesRules) {
            this.to = to;
            this.showDialog = true;
        } else {
            next();
        }
    },
    methods: {
        ...mapActions("rules", [
            "fetchRules",
            "saveOrUpdateRules",
            "syncRules"
        ]),
        async saveOrUpdate(model) {
            await this.saveOrUpdateRules(model);
            this.$refs["data-grid-component"].gridReload();
        },
        setDefaultFilter() {
            if (this.isFirstLoad) {
                this.$refs["data-grid-component"].gridInstance.columnOption(
                    "ruleActiveFg",
                    "filterValue",
                    true
                );
                this.isFirstLoad = false;
            }
        },
        onSync(payload) {
            if (payload.hasEditData) {
                this.showUnsavedChangesDialog = true;
            } else {
                this.synchronize(payload.updateUnsyncChanges);
            }
        },
        synchronize(updateUnsyncChanges) {
            this.syncRules()
                .then(() => {
                    updateUnsyncChanges();
                    notify(
                        {
                            message: "Alle Änderungen synchronisiert",
                            position: {
                                my: "center top",
                                at: "center top"
                            }
                        },
                        "success",
                        3000
                    );
                })
                .catch((error) =>
                    notify(
                        {
                            message: error,
                            position: {
                                my: "center top",
                                at: "center top"
                            }
                        },
                        "error",
                        3000
                    )
                )
                .finally(() => {
                    this.$refs["data-grid-component"].gridReload();
                });
        }
    }
};
</script>
