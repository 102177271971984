import service from "./api.js";

export function fetch() {
    return service({
        url: "/score-steps",
        method: "get"
    });
}

export function updateActive(data) {
    return service({
        url: "/score-steps/update-active",
        method: "put",
        data
    });
}

export function sync() {
    return service({
        url: "/score-steps/sync",
        method: "get"
    });
}