import api from "./api";

function getUserByMarket(marketId) {
    return api.get(`markets/${marketId}/users`).then((r) => r.data);
}

async function getUsers() {
    const response = await api.get("/users");
    return response.data;
}

async function getUserDetails(userId) {
    const response = await api.get("/user/" + userId);
    return response.data;
}

async function getIKAMS() {
    const response = await api.get("/users/ikams");
    return response.data;
}

async function updateUser(user) {
    const response = await api.put("/user/" + user.id, user);
    return response.data;
}

async function deleteUser(userId) {
    await api.delete("/user/" + userId);
}

async function createUser(user) {
    const response = await api.post("/users/", user);
    return response.data;
}

async function deactivateUser(userId) {
    await api.post("/user/" + userId + "/deactivate");
}

export default {
    getUserByMarket,
    getIKAMS,
    getUsers,
    getUserDetails,
    updateUser,
    deleteUser,
    createUser,
    deactivateUser
};
