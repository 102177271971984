<template src="./BlacklistDialingCodes.html"></template>

<script>
import { mapGetters, mapActions } from "vuex";
import GenericDataGrid from "@/components/GenericDataGrid";
import PageLeavePopup from "@/components/PageLeavePopup";
import UnsavedChangesPopup from "@/components/UnsavedChangesPopup";
import CustomStore from "devextreme/data/custom_store";
import { store } from "@/store/index";
import notify from "devextreme/ui/notify";
import { generateParams } from "@/utils/params";

const customStore = new CustomStore({
    key: "taLpsDialingCodeId",
    async load(loadOptions) {
        await store.dispatch(
            "blacklistDialingCodes/fetchBlacklistDialingCodes",
            generateParams(loadOptions)
        );
        return store.getters["blacklistDialingCodes/getBlacklistDialingCodes"];
    },
    totalCount() {
        return store.getters["blacklistDialingCodes/getTotalCount"];
    },
    insert() {},
    update() {}
});

export default {
    components: {
        GenericDataGrid,
        PageLeavePopup,
        UnsavedChangesPopup
    },
    name: "BlacklistDialingCodes",
    props: {
        unsyncChangesTable: String
    },
    data() {
        return {
            showUnsavedChangesDialog: false,
            isNew: false,
            dataSource: customStore,
            defaultValues: {
                dialingCodeActive: true,
                dialingCodeRuleLevel: -1,
                dialingCodeStartDt: new Date(Date.now()).toISOString()
            },
            gridColumns: [
                {
                    title: "ID",
                    dataField: "lpsDialingCodeId",
                    width: 70,
                    blockEditing: true,
                    blockSorting: true,
                    filterOperations: ["startswith"]
                },
                {
                    title: "Typ",
                    dataField: "dialingCodeType",
                    required: true,
                    blockSorting: true,
                    width: 100,
                    lookup: {
                        dataSource: [
                            { id: "Countries", name: "Countries" },
                            { id: "Werbung", name: "Werbung" }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                },
                {
                    title: "Name",
                    dataField: "dialingCodeName",
                    required: true,
                    blockSorting: true,
                    blockFiltering: true
                },
                {
                    title: "Wert",
                    dataField: "dialingCodeValue",
                    required: true,
                    blockSorting: true,
                    blockFiltering: true
                },
                {
                    title: "Aktiv",
                    dataField: "dialingCodeActive",
                    blockSorting: true,
                    blockFiltering: true,
                    width: 60,
                    lookup: {
                        dataSource: [
                            { id: false, name: "Inaktiv" },
                            { id: true, name: "Aktiv" }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                },
                {
                    title: "Start",
                    dataField: "dialingCodeStartDt",
                    required: true,
                    blockFiltering: true,
                    dataType: "datetime",
                    width: 100,
                    format: "dd.MM.yyyy",
                    editorOptions: {
                        displayFormat: "dd.MM.yyyy",
                        type: "date"
                    }
                },
                {
                    title: "LPS Level",
                    dataField: "dialingCodeRuleLevel",
                    required: true,
                    blockSorting: true,
                    blockFiltering: true,
                    width: 180,
                    lookup: {
                        dataSource: [
                            { id: -1, name: "Rot | Requests ablehnen" },
                            { id: -2, name: "Gelb | Manuelle Prüfung" },
                            { id: 0, name: "Grün | Okay, ggf. Parken" }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                },
                {
                    title: "Letzter Bearbeiter",
                    dataField: "dialingCodeUser",
                    blockSorting: true,
                    blockFiltering: true,
                    editorOptions: { readOnly: true }
                },
                {
                    title: "Letzte Änderung",
                    dataField: "dialingCodeTs",
                    format: "dd.MM.yyyy HH:mm",
                    dataType: "datetime",
                    blockSorting: true,
                    blockFiltering: true,
                    width: 130,
                    editorOptions: { readOnly: true }
                }
            ],
            defaultColumns: [
                "ID",
                "Typ",
                "Name",
                "Wert",
                "Aktiv",
                "Start",
                "LPS Level"
            ]
        };
    },
    computed: {
        ...mapGetters("blacklistDialingCodes", ["getBlacklistDialingCodes"]),
        formStructure() {
            return [
                {
                    title: "",
                    items: [
                        {
                            label: "Typ",
                            name: "dialingCodeType",
                            span: 1
                        },
                        {
                            label: "Name",
                            name: "dialingCodeName",
                            span: 3
                        },
                        {
                            label: "Wert",
                            name: "dialingCodeValue",
                            span: 1
                        },
                        {
                            label: "LPS Level",
                            name: "dialingCodeRuleLevel",
                            span: 2
                        },
                        {
                            label: "Start",
                            name: "dialingCodeStartDt",
                            span: 2
                        },
                        {
                            name: "dialingCodeActive",
                            span: 1,
                            hideLabel: true,
                            editorType: "dxSwitch",
                            editorOptions: {
                                switchedOffText: "Inaktiv",
                                switchedOnText: "Aktiv",
                                width: "80"
                            }
                        },
                        {
                            label: "Letzter Bearbeiter",
                            name: "dialingCodeUser",
                            span: 2,
                            hide: this.isNew
                        },
                        {
                            label: "Letzte Änderung",
                            name: "dialingCodeTs",
                            span: 2,
                            hide: this.isNew
                        },
                        {
                            label: "ID",
                            name: "lpsDialingCodeId",
                            span: 1,
                            hide: this.isNew
                        }
                    ]
                }
            ];
        }
    },
    mounted() {
        if (this.getBlacklistDialingCodes.length > 0) {
            const keys = Object.keys(this.getBlacklistDialingCodes[0]);
            keys.forEach((key) => {
                if (!this.gridColumns.find((x) => x.dataField === key)) {
                    this.gridColumns.push({
                        title: key,
                        dataField: key,
                        hideInColumnChooser: true
                    });
                }
            });
        }
    },
    methods: {
        ...mapActions("blacklistDialingCodes", [
            "fetchBlacklistDialingCodes",
            "saveOrUpdateBlacklistDialingCode",
            "syncBlacklistDialingCodes"
        ]),
        updateRowStatus(isNew) {
            this.isNew = isNew;
        },
        async saveOrUpdate(model) {
            await this.saveOrUpdateBlacklistDialingCode(model);
            this.$refs["data-grid-component"].gridReload();
        },
        onSync(payload) {
            if (payload.hasEditData) {
                this.showUnsavedChangesDialog = true;
            } else {
                this.synchronize(payload.updateUnsyncChanges);
            }
        },
        synchronize(updateUnsyncChanges) {
            this.syncBlacklistDialingCodes()
                .then(() => {
                    updateUnsyncChanges();
                    notify(
                        {
                            message: "Alle Änderungen synchronisiert",
                            position: {
                                my: "center top",
                                at: "center top"
                            }
                        },
                        "success",
                        3000
                    );
                })
                .catch((error) =>
                    notify(
                        {
                            message: error,
                            position: {
                                my: "center top",
                                at: "center top"
                            }
                        },
                        "error",
                        3000
                    )
                )
                .finally(() => {
                    this.$refs["data-grid-component"].gridReload();
                });
        }
    }
};
</script>
