<template src="./Error.html"></template>

<script>
import errors from "./Errors.json";

export default {
    computed: {
        statusCode() {
            const code = Number(this.$route.params.code);
            return !isNaN(code) ? code : null;
        },
        title() {
            if (this.statusCode && errors[this.statusCode]) {
                return errors[this.statusCode].title;
            }
            return errors["*"].title;
        },
        message() {
            if (this.statusCode && errors[this.statusCode]) {
                return errors[this.statusCode].message;
            }
            return errors["*"].message;
        }
    }
}
</script>

<style scoped src="./Error.css"></style>