<template src="./MonitoringDataGrid.html"></template>

<script>
import { DxDataGrid, DxColumn, DxPager, DxPaging } from "devextreme-vue/data-grid";

export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPager,
        DxPaging
    },
    name: "MonitoringDataGrid",
    props: {
        gridColumns: Array,
        dataSource: [Object, Array],
        noDataText: String,
        height: String
    },
    methods: {
        onRowPrepared(e) {
            this.$emit("rowPrepared", e);
        },
        onRowClick(e) {
            this.$emit("rowClick", e);
        },
    }
};
</script>

<style src="./MonitoringDataGrid.css"></style>
