import accountApi from "@/utils/api/accountApi";
import { getErrorTextsFromErrorResponse } from "@/utils/responseErrorUtils";
import { createNamespacedHelpers } from "vuex";

function handleReponseError(
    error,
    context
) {
    context.commit(
        "setErrors",
        getErrorTextsFromErrorResponse(error)
    );
}

const state = {
    session: null,
    errors: null
};

const getters = {
    getSession: (state) => {
        return state.session;
    },
    getErrors: (state) => {
        return state.errors;
    }
};

const mutations = {
    setSession: (state, payload) => {
        state.session = payload;
    },
    setErrors: (state, payload) => {
        state.errors = payload;
    }
};

const actions = {
    async logUserIn(
        context,
        payload
    ) {
        try {
            const session = await accountApi.logUserIn(
                payload.username,
                payload.password
            );

            if (session != null) {
                await context.commit("setSession", session);
            }
        } catch (e) {
            handleReponseError(e, context);
        }
    },
    async verifyLogin(context) {
        try {
            const session = await accountApi.verifyLogin();
            if (session != null) {
                await context.commit("setSession", session);
            }
        } catch (e) {
            handleReponseError(e, context);
        }
    },
    async logUserOut(context) {
        try {
            await accountApi.logUserOut();
            await context.commit("setSession", null);
        } catch (e) {
            handleReponseError(e, context);
        }
    },
    async requestPasswordReset(
        context,
        payload
    ) {
        try {
            await accountApi.requestPasswordReset(payload.email);
        } catch (e) {
            handleReponseError(e, context);
        }
    },
    async resetPassword (
        context,
        payload
    ) {
        try {
            await accountApi.resetPassword(payload.token, payload.newPassword);
        } catch (e) {
            handleReponseError(e, context);
        }
    },
    async activateAccount (
        context,
        payload
    ) {
        try {
            await accountApi.activateAccount(payload.token, payload.password);
        } catch (e) {
            handleReponseError(e, context);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

export const accountModule = createNamespacedHelpers("account");