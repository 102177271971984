export function leaveConfirmation(show, next) {
    if (show) {
        const confirm = window.confirm(
            "Die Seite, ohne die Änderungen zu speichern, verlassen?"
        );
        if (confirm) {
            next();
        }
    } else {
        next();
    }
}
