<template src="./Dealers.html"></template>

<script>
import { mapGetters, mapActions } from "vuex";
import DataGridHeader from "@/components/DataGridHeader";
import GenericDataGrid from "@/components/GenericDataGrid";
import PageLeavePopup from "@/components/PageLeavePopup";
import UnsavedChangesPopup from "@/components/UnsavedChangesPopup";
import CustomStore from "devextreme/data/custom_store";
import { store } from "@/store/index";
import notify from "devextreme/ui/notify";
import unsyncChangesTables from "@/constants/unsyncChangesTables";
import { generateParams } from "@/utils/params";

const customStore = new CustomStore({
    key: "taLpsDealerId",
    async load(loadOptions) {
        await store.dispatch(
            "dealers/fetchDealers",
            generateParams(loadOptions)
        );
        await store.dispatch("dealers/fetchUnprocessedDealersCount");

        return store.getters["dealers/getDealers"];
    },
    totalCount() {
        return store.getters["dealers/getTotalCount"];
    },
    insert() {},
    update() {}
});

export default {
    components: {
        DataGridHeader,
        GenericDataGrid,
        PageLeavePopup,
        UnsavedChangesPopup
    },
    name: "Dealers",
    data() {
        return {
            isBatchMode: false,
            showDialog: false,
            showUnsavedChangesDialog: false,
            to: null,
            isProcessable: false,
            dataSource: customStore,
            defaultValues: {},
            defaultColumns: [
                "Buno",
                "Händlername",
                "Händlerort",
                "Marke",
                "NL",
                "Grenze pro Tag",
                "Export-Option",
                "Sperre",
                "Aktiv",
                "Start",
                "Freigabe"
            ]
        };
    },
    computed: {
        ...mapGetters("dealers", ["getDealers"]),
        ...mapGetters("unsyncChanges", ["hasUnsyncChanges"]),
        formStructure() {
            return [
                {
                    title: "",
                    items: [
                        {
                            label: "Buno",
                            name: "dealerNbr",
                            span: 1,
                            editorOptions: { readOnly: true }
                        },
                        {
                            label: "Händlername",
                            name: "dealerName",
                            span: !this.isProcessable ? 6 : 7
                        },
                        {
                            name: "bmwBranchFg",
                            span: 1,
                            hideLabel: true,
                            editorType: "dxSwitch",
                            editorOptions: {
                                switchedOffText: "Keine NL",
                                switchedOnText: "NL",
                                width: "70"
                            }
                        },
                        {
                            name: "lpsBlockFg",
                            span: 1,
                            hide: this.isProcessable,
                            hideLabel: true,
                            editorType: "dxSwitch",
                            editorOptions: {
                                switchedOffText: "Keine Sperre",
                                switchedOnText: "Gesperrt",
                                width: "70"
                            }
                        },
                        {
                            name: "dealerActive",
                            span: 1,
                            hideLabel: true,
                            editorType: "dxSwitch",
                            editorOptions: {
                                switchedOffText: "Inaktiv",
                                switchedOnText: "Aktiv",
                                width: "70"
                            }
                        },
                        {
                            label: "Start",
                            name: "dealerStartDt",
                            span: 1
                        },
                        {
                            label: "Marke",
                            name: "brand",
                            span: 1
                        },
                        {
                            label: "Händlerort",
                            name: "dealerCity",
                            span: !this.isProcessable ? 5 : 6
                        },
                        {
                            label: "GrenzeProTag",
                            name: "topCntForwardingPerDay",
                            span: 1
                        },
                        {
                            name: "dealerExportOption",
                            span: 1,
                            hideLabel: true,
                            editorType: "dxSwitch",
                            editorOptions: {
                                switchedOffText: "Variante1",
                                switchedOnText: "Variante2",
                                width: "70"
                            }
                        },
                        {
                            name: "dealerApproved",
                            span: 1,
                            hide: this.isProcessable,
                            hideLabel: true,
                            editorType: "dxSwitch",
                            editorOptions: {
                                switchedOffText: "Offen",
                                switchedOnText: "Freigabe",
                                width: "70"
                            }
                        }
                    ]
                }
            ];
        },
        gridColumns() {
            return [
                {
                    title: "Buno",
                    dataField: "dealerNbr",
                    width: 95,
                    blockEditing: true,
                    blockSorting: true,
                    filterOperations: ["startswith"],
                    editorOptions: { maxLength: 5 }
                },
                {
                    title: "Händlername",
                    dataField: "dealerName",
                    blockSorting: true,
                    blockFiltering: true,
                    editorOptions: { maxLength: 255 }
                },
                {
                    title: "Händlerort",
                    dataField: "dealerCity",
                    blockSorting: true,
                    blockFiltering: true,
                    editorOptions: { maxLength: 255 }
                },
                {
                    title: "Marke",
                    dataField: "brand",
                    blockSorting: true,
                    blockFiltering: true,
                    width: 80,
                    editorOptions: { readOnly: true, maxLength: 5 }
                },
                {
                    title: "Export-Option",
                    dataField: "dealerExportOption",
                    blockSorting: true,
                    width: 107,
                    lookup: {
                        dataSource: [
                            { id: false, name: "Variante1" },
                            { id: true, name: "Variante2" }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                },
                {
                    title: "Grenze pro Tag",
                    dataField: "topCntForwardingPerDay",
                    blockSorting: true,
                    width: 70,
                    required: true,
                    filterOperations: ["="],
                    editorOptions: {
                        max: 65,
                        min: -1
                    }
                },
                this.nlColumn,
                this.sperreColumn,
                this.aktivColumn,
                {
                    title: "Start",
                    dataField: "dealerStartDt",
                    blockFiltering: true,
                    width: 115,
                    dataType: "datetime",
                    required: true,
                    format: "dd.MM.yyyy",
                    editorOptions: { displayFormat: "dd.MM.yyyy", type: "date" }
                },
                {
                    title: "Freigabe",
                    dataField: "dealerApproved",
                    blockFiltering: true,
                    blockSorting: true,
                    width: 105,
                    lookup: {
                        dataSource: [
                            { id: false, name: "Offen" },
                            { id: true, name: "Freigabe" }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                },
                {
                    title: "Letzter Bearbeiter",
                    dataField: "dealerUser",
                    blockSorting: true,
                    blockFiltering: true,
                    width: 140,
                    editorOptions: { readOnly: true }
                },
                {
                    title: "Letzte Änderung",
                    dataField: "dealerTs",
                    dataType: "datetime",
                    blockSorting: true,
                    blockFiltering: true,
                    format: "dd.MM.yyyy HH:mm",
                    width: 150,
                    editorOptions: {
                        readOnly: true,
                        displayFormat: "dd.MM.yyyy HH:mm",
                        type: "datetime"
                    }
                }
            ];
        },
        nlColumn() {
            if (this.isBatchMode)
                return {
                    title: "NL",
                    dataField: "bmwBranchFg",
                    blockSorting: true,
                    editorType: "dxSwitch",
                    width: 70,
                    trueText: "Aktiv",
                    falseText: "Inaktiv"
                };
            else
                return {
                    title: "NL",
                    dataField: "bmwBranchFg",
                    blockSorting: true,
                    width: 105,
                    editorType: "dxLookup",
                    lookup: {
                        dataSource: [
                            { id: false, name: "Keine NL" },
                            { id: true, name: "NL" }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                };
        },
        sperreColumn() {
            if (this.isBatchMode)
                return {
                    title: "Sperre",
                    dataField: "lpsBlockFg",
                    blockSorting: true,
                    blockFiltering: true,
                    editorType: "dxSwitch",
                    width: 70
                };
            else
                return {
                    title: "Sperre",
                    dataField: "lpsBlockFg",
                    editorType: "dxLookup",
                    blockSorting: true,
                    blockFiltering: true,
                    width: 130,
                    lookup: {
                        dataSource: [
                            { id: false, name: "Keine Sperre" },
                            { id: true, name: "Gesperrt" }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                };
        },
        aktivColumn() {
            if (this.isBatchMode)
                return {
                    title: "Aktiv",
                    dataField: "dealerActive",
                    blockSorting: true,
                    blockFiltering: true,
                    editorType: "dxSwitch",
                    width: 70
                };
            else
                return {
                    title: "Aktiv",
                    dataField: "dealerActive",
                    editorType: "dxLookup",
                    blockSorting: true,
                    blockFiltering: true,
                    width: 90,
                    lookup: {
                        dataSource: [
                            { id: false, name: "Inaktiv" },
                            { id: true, name: "Aktiv" }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                };
        },
        unsyncChangesDealers() {
            return unsyncChangesTables.DEALERS;
        },
        hasUnsyncChangesDealers() {
            return this.hasUnsyncChanges(this.unsyncChangesDealers);
        }
    },
    beforeRouteLeave(to, _, next) {
        if (to.path == "/login") {
            next();
        } else if (this.hasUnsyncChangesDealers) {
            this.to = to;
            this.showDialog = true;
        } else {
            next();
        }
    },
    methods: {
        ...mapActions("dealers", [
            "fetchDealers",
            "updateDealerStatus",
            "updateDealer",
            "updateDealers",
            "syncDealers"
        ]),
        updateProcessiable(record) {
            if (record.dealerApproved == 0) {
                this.isProcessable = true;
            } else {
                this.isProcessable = false;
            }
        },
        async updateStatus(dealer, status) {
            await this.updateDealerStatus({
                model: dealer,
                status
            });
            this.$refs["data-grid-component"].gridReload();
        },
        async saveOrUpdate(model) {
            await this.updateDealer(model);
            this.$refs["data-grid-component"].gridReload();
        },
        async batchUpdate(models) {
            await this.updateDealers(models);
            this.$refs["data-grid-component"].gridReload();
        },
        updateIsBatchMode(isBatchMode) {
            this.$nextTick(() => (this.isBatchMode = isBatchMode));
        },
        onSync(payload) {
            if (payload.hasEditData) {
                this.showUnsavedChangesDialog = true;
            } else {
                this.synchronize(payload.updateUnsyncChanges);
            }
        },
        synchronize(updateUnsyncChanges) {
            this.syncDealers()
                .then(() => {
                    updateUnsyncChanges();
                    notify(
                        {
                            message: "Alle Änderungen synchronisiert",
                            position: {
                                my: "center top",
                                at: "center top"
                            }
                        },
                        "success",
                        3000
                    );
                })
                .catch((error) =>
                    notify(
                        {
                            message: error,
                            position: {
                                my: "center top",
                                at: "center top"
                            }
                        },
                        "error",
                        3000
                    )
                )
                .finally(() => {
                    this.$refs["data-grid-component"].gridReload();
                });
        }
    }
};
</script>

<style src="./Dealers.css"></style>
