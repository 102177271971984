<template src="./ConfirmationPopup.html"></template>

<script>
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";

export default {
    components: {
        DxPopup,
        DxToolbarItem
    },
    props: {
        showDialog: Boolean,
        popupTitle: {
            type: String,
            default: "Default Title"
        },
        confirmButtonText: {
            type: String,
            default: "Bestätigen"
        },
        cancelButtonText: {
            type: String,
            default: "Abschaffen"
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 200
        }
    },
    data() {
        return {
            confirmButtonOptions: {
                text: this.confirmButtonText,
                onClick: this.confirmButtonClicked
            },
            cancelButtonOptions: {
                text: this.cancelButtonText,
                onClick: this.cancelButtonClicked
            }
        };
    },
    methods: {
        confirmButtonClicked() {
            this.$emit("confirmButtonClicked");
            this.closeDialog();
        },

        cancelButtonClicked() {
            this.$emit("cancelButtonClicked");
            this.closeDialog();
        },

        closeDialog() {
            this.$emit("update:showDialog", false);
        },
    },
};
</script>
