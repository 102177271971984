export function getErrorTextsFromErrorResponse(axiosError) {
    if (axiosError.response === undefined) return [axiosError.message];
    if (axiosError.response.data === undefined)
        return [axiosError.response.statusText];

    const validationData = axiosError.response.data;

    if (validationData.errors !== undefined) {
        return Object.entries(validationData.errors).map((axiosError) => {
            return `validation-problem -> property: ${axiosError[0]} message: ${axiosError[1]}`;
        });
    }

    return [axiosError.response.statusText, axiosError.response.data];
}
