<template src="./Monitoring.html"></template>

<script>
import { mapGetters, mapActions } from "vuex";
import { DxDataGrid, DxColumn } from "devextreme-vue/data-grid";
import DataGridHeader from "@/components/DataGridHeader";
import MonitoringDataGrid from "@/components/MonitoringDataGrid";

export default {
    components: {
        DxDataGrid,
        DxColumn,
        DataGridHeader,
        MonitoringDataGrid
    },
    name: "Monitoring",
    data() {
        return {
            statusRequestsNoDataText: "No Data",
            processLogsNoDataText: "No Data",
            statusProcessPartsNoDataText: "No Data",
            lastUpdate: new Date().toLocaleString("ru-RU"),
            statusRequestsColumns: [
                {
                    title: "Status Nr.",
                    dataField: "lpsStatusNbr",
                    width: 100
                },
                {
                    title: "Requests",
                    dataField: "anzahlRequests",
                    width: 100
                },
                {
                    title: "Status",
                    dataField: "statusName"
                },
                {
                    title: "Bewertung",
                    dataField: "bewertung"
                },
                {
                    title: "Min Src Dt",
                    dataField: "minSourceDate",
                    dataType: "datetime",
                    format: "dd.MM.yyyy HH:mm:ss",
                    width: 150
                }
            ],
            processLogsColumns: [
                {
                    title: "ID",
                    dataField: "processLogId",
                    width: 100
                },
                {
                    title: "Prozedur",
                    dataField: "procName"
                },
                {
                    title: "Status",
                    dataField: "logStatus",
                    width: 90
                },
                {
                    title: "Log-Nachricht",
                    dataField: "logMessage"
                },
                {
                    title: "Anzahl",
                    dataField: "amount",
                    width: 70
                },
                {
                    title: "Dauer",
                    dataField: "duration",
                    width: 70
                },
                {
                    title: "Start",
                    dataField: "beginDt",
                    dataType: "datetime",
                    format: "dd.MM.yyyy HH:mm:ss",
                    width: 150
                },
                {
                    title: "Ende",
                    dataField: "endDt",
                    dataType: "datetime",
                    format: "dd.MM.yyyy HH:mm:ss",
                    width: 150
                }
            ],
            statusProcessPartsColumns: [
                {
                    title: "Prozess",
                    dataField: "process",
                    width: 250
                },
                {
                    title: "Letzter Start",
                    dataField: "lastRunStart",
                    dataType: "datetime",
                    format: "dd.MM.yyyy HH:mm:ss",
                    width: 150
                },
                {
                    title: "Status",
                    dataField: "processStatus",
                    width: 90
                },
                {
                    title: "Erwartung",
                    dataField: "erwartung"
                },
                {
                    title: "Anzahl letzter Lauf",
                    dataField: "lastRunCount"
                },
                {
                    title: "Inhalte",
                    dataField: "inhalte",
                    width: 90
                }
            ]
        };
    },
    computed: {
        ...mapGetters("statusRequests", ["getStatusRequests"]),
        ...mapGetters("processLogs", ["getProcessLogs"]),
        ...mapGetters("statusProcessParts", ["getStatusProcessParts"])
    },
    mounted() {
        this.fetchStatusRequests().catch((error) => {
            this.statusRequestsNoDataText = error.toString();
        });
        this.fetchProcessLogs().catch((error) => {
            this.processLogsNoDataText = error.toString();
        });
        this.fetchStatusProcessParts().catch((error) => {
            this.statusProcessPartsNoDataText = error.toString();
        });
    },
    methods: {
        ...mapActions("statusRequests", ["fetchStatusRequests"]),
        ...mapActions("processLogs", ["fetchProcessLogs"]),
        ...mapActions("statusProcessParts", ["fetchStatusProcessParts"]),
        onRowPreparedStatusRequests(e) {
            if (
                e.rowType == "data" &&
                e.data.bewertung?.toLowerCase().includes("prüfen")
            ) {
                e.rowElement.style.backgroundColor = "#ECBA54";
                e.rowElement.className = e.rowElement.className.replace(
                    "dx-row-alt",
                    ""
                );
            }
        },
        onRowPreparedRocessLogs(e) {
            if (e.rowType == "data" && e.data.logStatus == "Error") {
                e.rowElement.style.backgroundColor = "#ECBA54";
                e.rowElement.className = e.rowElement.className.replace(
                    "dx-row-alt",
                    ""
                );
            }
        },
        onRowPreparedStatusProcessParts(e) {
            if (e.rowType == "data") {
                if (e.data.processStatus != "OK" || e.data.inhalte == "Auffällig") {
                    e.rowElement.style.backgroundColor = "#ECBA54";
                } else {
                    e.rowElement.style.backgroundColor = "#B8DC9C";
                }
                e.rowElement.className = e.rowElement.className.replace(
                    "dx-row-alt",
                    ""
                );
            }
        },
        redirectToManualChecks(e) {
            if (e.rowType == "data" && e.data.lpsStatusNbr == -2) {
                this.$router.push("/manual-checks");
            }
        }
    }
};
</script>

<style src="./Monitoring.css"></style>
