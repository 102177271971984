export default {
    BadRequest: {
        Error: "Bad Request",
        Message: "Ungültiger Benutzername oder Passwort."
    },
    NetworkError: {
        Error: "Network Error",
        Message: "Verbindung zum Server ist fehlgeschlagen.."
    },
    Default: {
        Message: "Unbekannter Fehler."
    }
}