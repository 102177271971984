<template src="./PageLeavePopup.html"></template>

<script>
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
import { mapGetters, mapActions } from "vuex";

export default {
    components: {
        DxPopup,
        DxToolbarItem
    },
    name: "PageLeavePopup",
    props: {
        to: Object,
        showDialog: Boolean,
        unsyncChangesTable: [String, Array]
    },
    data() {
        return {
            syncButtonOptions: {
                text: "Ja, jetzt synchronisieren",
                onClick: this.syncAndLeavePage
            },
            leaveButtonOptions: {
                text: "Nein, erstmal nicht",
                onClick: this.leavePage
            }
        };
    },
    computed: {
        ...mapGetters("unsyncChanges", ["hasUnsyncChanges"])
    },
    methods: {
        ...mapActions("unsyncChanges", ["updateUnsyncChanges"]),
        closingDialog() {
            this.$emit("update:to", null);
            this.$emit("update:showDialog", false);
        },
        async syncAndLeavePage() {
            if (Array.isArray(this.unsyncChangesTable)) {
                this.$emit("sync", {
                    syncAll: true,
                    updateUnsyncChanges: () => {
                        this.unsyncChangesTable.forEach((table) => {
                            this.updateUnsyncChanges({
                                table: table,
                                value: false
                            });
                        });
                    }
                });
            } else {
                this.$emit("sync", () =>
                    this.updateUnsyncChanges({
                        table: this.unsyncChangesTable,
                        value: false
                    })
                );
            }

            this.leavePage();
        },
        leavePage() {
            if (Array.isArray(this.unsyncChangesTable)) {
                var tempArray = [];
                this.unsyncChangesTable.forEach((table) => {
                    tempArray.push(this.hasUnsyncChanges(table));
                    this.updateUnsyncChanges({
                        table: table,
                        value: false
                    });
                });

                this.$router.push(this.to);

                this.unsyncChangesTable.forEach((table) => {
                    this.updateUnsyncChanges({
                        table: table,
                        value: tempArray.shift()
                    });
                });
            } else {
                //This code is needed to temprorary set unsyncChanges to false.
                //Routing here trigger parent component beforeRouteLeave which will redirect only if unsyncChanges false
                var temp = this.hasUnsyncChanges(this.unsyncChangesTable);
                this.updateUnsyncChanges({
                    table: this.unsyncChangesTable,
                    value: false
                });

                this.$router.push(this.to);

                this.updateUnsyncChanges({
                    table: this.unsyncChangesTable,
                    value: temp
                });
            }
        }
    }
};
</script>
