<template src="./UnsavedChangesPopup.html"></template>

<script>
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
import { mapActions } from "vuex";

export default {
    components: {
        DxPopup,
        DxToolbarItem
    },
    name: "UnsavedChangesPopup",
    props: {
        showDialog: Boolean,
        unsyncChangesTable: String
    },
    data() {
        return {
            okButtonOptions: {
                text: "Verstanden",
                onClick: this.closingDialog
            }
        };
    },
    methods: {
        ...mapActions("unsyncChanges", ["updateUnsyncChanges"]),
        closingDialog() {
            this.$emit("update:showDialog", false);
            this.$emit("sync", () =>
                this.updateUnsyncChanges({
                    table: this.unsyncChangesTable,
                    value: false
                })
            );
        }
    }
};
</script>
