<template src="./StatusNumbers.html"></template>

<script>
import { mapGetters, mapActions } from "vuex";
import GenericDataGrid from "@/components/GenericDataGrid";
import DataGridHeader from "@/components/DataGridHeader";
import PageLeavePopup from "@/components/PageLeavePopup";
import UnsavedChangesPopup from "@/components/UnsavedChangesPopup";
import CustomStore from "devextreme/data/custom_store";
import { store } from "@/store/index";
import notify from "devextreme/ui/notify";
import unsyncChangesTables from "@/constants/unsyncChangesTables";

const customStore = new CustomStore({
    key: "taLpsStatusNumberId",
    loadMode: "raw",
    async load() {
        await store.dispatch("statusNumbers/fetchStatusNumbers");
        return store.getters["statusNumbers/getStatusNumbers"];
    },
    insert() {},
    update() {}
});

export default {
    components: {
        GenericDataGrid,
        DataGridHeader,
        PageLeavePopup,
        UnsavedChangesPopup
    },
    name: "StatusNumbers",
    data() {
        return {
            showDialog: false,
            showUnsavedChangesDialog: false,
            to: null,
            dataSource: customStore,
            defaultValues: {
                statusNumberActive: true,
                statusNumberApproved: true,
                statusNumberStartDt: new Date(Date.now()).toISOString()
            },
            gridColumns: [
                {
                    title: "ID",
                    dataField: "lpsStatusNumberId",
                    width: 60,
                    filterOperations: ["contains"]
                },
                {
                    title: "Nr.",
                    dataField: "statusNbr",
                    required: true,
                    width: 60,
                    filterOperations: ["contains"]
                },
                {
                    title: "Statusname",
                    dataField: "statusName",
                    required: true,
                    editorOptions: { maxLength: 255 },
                    filterOperations: ["contains"]
                },
                {
                    title: "Reportanzeige",
                    dataField: "statusReportName",
                    filterOperations: ["contains"]
                },
                {
                    title: "Aktiv",
                    dataField: "statusNumberActive",
                    width: 90,
                    lookup: {
                        dataSource: [
                            { id: false, name: "Inaktiv" },
                            { id: true, name: "Aktiv" }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                },
                {
                    title: "Start",
                    dataField: "statusNumberStartDt",
                    dataType: "datetime",
                    width: 145,
                    required: true,
                    format: "dd.MM.yyyy",
                    editorOptions: { type: "date" }
                },
                {
                    title: "Freigabe",
                    dataField: "statusNumberApproved",
                    width: 105,
                    lookup: {
                        dataSource: [
                            { id: false, name: "Offen" },
                            { id: true, name: "Freigabe" }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                },
                {
                    title: "Letzter Bearbeiter",
                    dataField: "statusNumberUser",
                    width: 140,
                    blockEditing: true,
                    filterOperations: ["contains"]
                },
                {
                    title: "Letzte Änderung",
                    dataField: "statusNumberTs",
                    dataType: "datetime",
                    blockEditing: true,
                    width: 180,
                    format: "dd.MM.yyyy HH:mm",
                    editorOptions: {
                        displayFormat: "dd.MM.yyyy HH:mm",
                        type: "datetime"
                    }
                }
            ],
            defaultColumns: [
                "Nr.",
                "Statusname",
                "Reportanzeige",
                "Aktiv",
                "Start",
                "Freigabe",
                "Letzter Bearbeiter",
                "Letzte Änderung"
            ],
            formStructure: [
                {
                    title: "",
                    items: [
                        {
                            label: "Statusnummer",
                            name: "statusNbr",
                            span: 1
                        },
                        {
                            label: "Statusname",
                            name: "statusName",
                            span: 4
                        },
                        {
                            label: "Kommentar",
                            name: "comment",
                            span: 4
                        },
                        {
                            name: "statusNumberActive",
                            hideLabel: true,
                            span: 1,
                            editorType: "dxSwitch",
                            editorOptions: {
                                switchedOffText: "Inaktiv",
                                switchedOnText: "Aktiv",
                                width: "70"
                            }
                        },
                        {
                            label: "Start",
                            name: "statusNumberStartDt",
                            span: 1
                        },
                        {
                            label: "Reportanzeige",
                            name: "statusReportName",
                            span: 8
                        },
                        {
                            name: "statusNumberApproved",
                            hideLabel: true,
                            span: 1,
                            editorType: "dxSwitch",
                            editorOptions: {
                                switchedOffText: "Offen",
                                switchedOnText: "Freigabe",
                                width: "70"
                            }
                        }
                    ]
                }
            ]
        };
    },
    computed: {
        ...mapGetters("statusNumbers", ["getStatusNumbers"]),
        ...mapGetters("unsyncChanges", ["hasUnsyncChanges"]),
        unsyncChangesStatusNumbers() {
            return unsyncChangesTables.STATUS_NUMBERS;
        },
        hasUnsyncChangesStatusNumbers() {
            return this.hasUnsyncChanges(this.unsyncChangesStatusNumbers);
        }
    },
    mounted() {
        this.fetchStatusNumbers().then(() => {
            if (this.getStatusNumbers.length > 0) {
                const keys = Object.keys(this.getStatusNumbers[0]);
                keys.forEach((key) => {
                    if (!this.gridColumns.find((x) => x.dataField === key)) {
                        this.gridColumns.push({
                            title: key,
                            dataField: key,
                            hideInColumnChooser: true
                        });
                    }
                });
            }
        });
    },
    beforeRouteLeave(to, _, next) {
        if (to.path == "/login") {
            next();
        } else if (this.hasUnsyncChangesStatusNumbers) {
            this.to = to;
            this.showDialog = true;
        } else {
            next();
        }
    },
    methods: {
        ...mapActions("statusNumbers", [
            "fetchStatusNumbers",
            "saveOrUpdateStatusNumber",
            "syncStatusNumbers"
        ]),
        async saveOrUpdate(model) {
            await this.saveOrUpdateStatusNumber(model);
            this.$refs["data-grid-component"].gridReload();
        },
        onSync(payload) {
            if (payload.hasEditData) {
                this.showUnsavedChangesDialog = true;
            } else {
                this.synchronize(payload.updateUnsyncChanges);
            }
        },
        synchronize(updateUnsyncChanges) {
            this.syncStatusNumbers()
                .then(() => {
                    updateUnsyncChanges();
                    notify(
                        {
                            message: "Alle Änderungen synchronisiert",
                            position: {
                                my: "center top",
                                at: "center top"
                            }
                        },
                        "success",
                        3000
                    );
                })
                .catch((error) =>
                    notify(
                        {
                            message: error,
                            position: {
                                my: "center top",
                                at: "center top"
                            }
                        },
                        "error",
                        3000
                    )
                )
                .finally(() => this.$refs["data-grid-component"].gridReload());
        }
    }
};
</script>
