import { fetch } from "@/utils/api/statusProcessParts.js";

const state = {
    statusProcessParts: []
};

const getters = {
    getStatusProcessParts: (state) => {
        return state.statusProcessParts;
    }
};

const mutations = {
    SET_STATUS_PROCESS_PARTS: (state, data) => {
        state.statusProcessParts = data;
    }
};

const actions = {
    fetchStatusProcessParts({ commit }) {
        return new Promise((resolve, reject) => {
            fetch()
                .then((response) => {
                    commit("SET_STATUS_PROCESS_PARTS", response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
