import service from "./api.js";

export function fetchByPage(data) {
    return service({
        url: "/campaigns",
        method: "post",
        data
    });
}

export function fetchUnprocessedCount(data) {
    return service({
        url: "/campaigns/unprocessed-count",
        method: "get",
        data
    });
}

export function updateStatus(data) {
    return service({
        url: "/campaigns/update-status",
        method: "put",
        data
    });
}

export function update(data) {
    return service({
        url: "/campaigns/update",
        method: "put",
        data
    });
}

export function sync() {
    return service({
        url: "/campaigns/sync",
        method: "get"
    });
}

export function getEmailRecipients() {
    return service({
        url: "/campaigns/get-mails",
        method: "get"
    });
}