<template src="./UserBadge.html"></template>

<script>
import AppIcon from "@/components/AppIcon";
import { store } from "@/store/index";

export default {
    components: {
        AppIcon
    },
    data() {
        return {
            loginPath: "/login"
        }
    },
    computed: {
        session() {
            return store.getters["account/getSession"];
        }
    },
    methods: {
        async logout() {
            store.dispatch("account/logUserOut");
            this.$router.push("/login");
        },
        fullname() {
            return this.session?.user.firstName + " " + this.session?.user.lastName;
        }
    }
}
</script>

<style src="./UserBadge.css" scoped></style>
