import userApi from "@/utils/api/userApi";
import { getErrorTextsFromErrorResponse } from "@/utils/responseErrorUtils";
import { createNamespacedHelpers } from "vuex";

const state = {
    users: [],
    user: null,
    userChanges: null,
    errors: null,
    loading: false,
    isEdited: false
};

async function loadUsers(
    commit,
    load
) {
    commit("setLoading", true);
    try {
        const users = await load();
        commit("setUsers", users);
    } catch {
        commit("setErrors", ["Error"]);
    } finally {
        commit("setLoading", false);
    }
}

const getters = {
    getUsers: (state) => {
        return state.users;
    },
    getUser: (state) => {
        return state.user;
    },
    isLoading: (state) => {
        return state.loading;
    },
    getErrors: (state) => {
        return state.errors;
    },
    isEdited: (state) => {
        return state.isEdited;
    }
};

const mutations = {
    setUsers: (state, payload) => {
        state.users = payload;
    },
    setErrors: (state, payload) => {
        state.errors = payload;
    },
    setLoading: (state, payload) => {
        state.loading = payload;
    },
    removeUserById: (state, payload) => {
        state.users = state.users.filter((u) => u.id !== payload);
    },
    setUser: (state, payload) => {
        state.userChanges = { id: payload?.id ?? null };
        state.user = payload;
        state.isEdited = false;
    },
    updateUser: (state, payload) => {
        if (state.user) {
            state.userChanges = { ...state.userChanges, ...payload };
            state.user = { ...state.user, ...payload };
            state.isEdited = true;
        }
    },
};

const actions = {
    async loadUsers({ commit }) {
        await loadUsers(commit, userApi.getUsers);
    },
    async deleteUserById({ commit }, payload) {
        await userApi.deleteUser(payload);
        commit("removeUserById", payload);
    },
    async loadUserDetailsById({ commit }, payload) {
        commit("setLoading", true);
        try {
            const user = await userApi.getUserDetails(payload);
            commit("setUser", user);
        } catch {
            commit("setErrors", ["Error"]);
        } finally {
            commit("setLoading", false);
        }
    },
    addNewUser({ commit }) {
        let date = new Date();
        // Set started date time to noon to prevent different dates for timezones.
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12);

        const user = {
            firstName: "",
            lastName: "",
            email: "",
            startedDate: date,
            active: true
        };
        commit("setUser", user);
    },
    async commitUserUpdate(context) {
        try {
            if (context.state.userChanges) {
                await userApi.updateUser(context.state.userChanges);
            }
        } catch (e) {
            handleReponseError(e, context);
        }
    },
    async createUser(context) {
        try {
            if (context.state.user) {
                await userApi.createUser(context.state.user);
            }
        } catch (e) {
            handleReponseError(e, context);
        }
    },
    async deactivateUser(context) {
        try {
            if (context.state.user) {
                await userApi.deactivateUser(context.state.user.id);
            }
        } catch (e) {
            handleReponseError(e, context);
        }
    }
};

export function handleReponseError(
    error,
    context
) {
    context.commit(
        "setErrors",
        getErrorTextsFromErrorResponse(error)
    );
}

export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions
};

export const userModule = createNamespacedHelpers("users");