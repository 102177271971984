import {
    fetchByPage,
    updateStatus,
    update,
    sync,
    fetchUnprocessedCount,
    getEmailRecipients
} from "@/utils/api/campaigns.js";

const state = {
    campaigns: [],
    unprocessedCampaignsCount: null,
    totalCount: null,
    emailRecipients: null,
    emailCCRecipients: null,
};

const getters = {
    getCampaigns: (state) => {
        return state.campaigns;
    },
    getUnprocessedCampaignsCount: (state) => {
        return state.unprocessedCampaignsCount;
    },
    getTotalCount: (state) => {
        return state.totalCount;
    },
    getEmailRecipients: (state) => {
         return state.emailRecipients;
    },
    getEmailCCRecipients: (state) => {
        return state.emailCCRecipients;
    }
};

const mutations = {
    SET_CAMPAIGNS: (state, data) => {
        state.campaigns = data.data.campaigns;
        state.totalCount = data.data.totalCount;
    },
    SET_UNPROCESSED_CAMPAIGNS_COUNT: (state, data) => {
        state.unprocessedCampaignsCount = data;
    },
    SET_EMAIL_RECIPIENTS: (state, data) => {
        state.emailRecipients = data.recipients;
        state.emailCCRecipients = data.ccRecipients;
    }
};

const actions = {
    fetchCampaigns({ commit }, query) {
        return new Promise((resolve, reject) => {
            fetchByPage(query)
                .then((response) => {
                    commit("SET_CAMPAIGNS", response.data);
                    resolve(response);
                    // dispatch("fetchUnprocessedCampaignsCount");
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    fetchUnprocessedCampaignsCount({ commit }) {
        return new Promise((resolve, reject) => {
            fetchUnprocessedCount()
                .then((response) => {
                    commit("SET_UNPROCESSED_CAMPAIGNS_COUNT", response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateCampaignStatus(_, data) {
        return new Promise((resolve, reject) => {
            updateStatus(data)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateCampaign(_, data) {
        return new Promise((resolve, reject) => {
            update(data)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    syncCampaigns() {
        return new Promise((resolve, reject) => {
            sync()
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    fetchEmailRecipients({ commit }) {
        return new Promise((resolve, reject) => {
            getEmailRecipients()
                .then((response) => {
                    commit("SET_EMAIL_RECIPIENTS", response.data);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
