<template src="./MainLayout.html"></template>

<script>
import SideMenu from "@/components/SideMenu";
import AppFooter from "@/components/AppFooter";
import AppHeader from "@/components/AppHeader";

export default {
    components: {
        SideMenu,
        AppFooter,
        AppHeader
    }
}
</script>

<style src="./MainLayout.css" scoped></style>