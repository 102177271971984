export function generateParams(loadOptions) {
    var params = { filterQueries: [] };
    if (loadOptions.sort?.length > 0) {
        params.selector = loadOptions.sort[0].selector;
        params.desc = loadOptions.sort[0].desc;
    }
    if (loadOptions.filter) {
        if (loadOptions.filter.filterValue !== undefined) {
            params.filterQueries.push({
                name: loadOptions.filter[0],
                value: loadOptions.filter.filterValue
            });
        } else {
            loadOptions.filter?.forEach((filter) => {
                if (Array.isArray(filter)) {
                    params.filterQueries.push({
                        name: filter[0],
                        value: filter.filterValue
                    });
                }
            });
        }
    }
    params.skip = loadOptions.skip;
    params.take = loadOptions.take;

    return params;
}
