import roleApi from "@/utils/api/roleApi";
import { createNamespacedHelpers } from "vuex";

const state = {
    roles: [],
    errors: null,
    loading: false
};

const getters = {
    getRoles: (state) => {
        return state.roles;
    },
    isLoading: (state) => {
        return state.loading;
    },
    getErrors: (state) => {
        return state.errors;
    }
};

const mutations = {
    setRoles: (state, payload) => {
        state.roles = payload;
    },
    setErrors: (state, payload) => {
        state.errors = payload;
    },
    setLoading: (state, payload) => {
        if (payload) {
            state.roles = [];
            state.errors = null;
        }
        state.loading = payload;
    }
};

const actions = {
    async loadRoles({ commit }) {
        commit("setLoading", true);
        try {
            const roles = await roleApi.getRoles();
            commit("setRoles", roles);
        } catch {
            commit("setErrors", ["Error"]);
        } finally {
            commit("setLoading", false);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

export const roleModule = createNamespacedHelpers("roles");