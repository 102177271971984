import { fetch } from "@/utils/api/statusRequests.js";

const state = {
    statusRequests: []
};

const getters = {
    getStatusRequests: (state) => {
        return state.statusRequests;
    }
};

const mutations = {
    SET_STATUS_REQUESTS: (state, data) => {
        state.statusRequests = data;
    }
};

const actions = {
    fetchStatusRequests({ commit }) {
        return new Promise((resolve, reject) => {
            fetch()
                .then((response) => {
                    commit("SET_STATUS_REQUESTS", response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
