import {
    fetchByPage,
    updateStatus,
    update,
    multipleUpdate,
    sync,
    fetchUnprocessedCount,
    multipleForwarding
} from "@/utils/api/vehicles.js";

const state = {
    vehicles: [],
    unprocessedVehiclesCount: null,
    totalCount: null
};

const getters = {
    getVehicles: (state) => {
        return state.vehicles;
    },
    getUnprocessedVehiclesCount: (state) => {
        return state.unprocessedVehiclesCount;
    },
    getTotalCount: (state) => {
        return state.totalCount;
    }
};

const mutations = {
    SET_VEHICLES: (state, data) => {
        state.vehicles = data.data.vehicles;
        state.totalCount = data.data.totalCount;
    },
    SET_UNPROCESSED_VEHICLES_COUNT: (state, data) => {
        state.unprocessedVehiclesCount = data;
    }
};

const actions = {
    fetchVehicles({ commit }, query) {
        return new Promise((resolve, reject) => {
            fetchByPage(query)
                .then((response) => {
                    commit("SET_VEHICLES", response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    fetchUnprocessedVehiclesCount({ commit }) {
        return new Promise((resolve, reject) => {
            fetchUnprocessedCount()
                .then((response) => {
                    commit("SET_UNPROCESSED_VEHICLES_COUNT", response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateVehicleStatus(_, data) {
        return new Promise((resolve, reject) => {
            updateStatus(data)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateVehicle(_, data) {
        return new Promise((resolve, reject) => {
            update(data)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateVehicles(_, data) {
        return new Promise((resolve, reject) => {
            multipleUpdate(data)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    syncVehicles() {
        return new Promise((resolve, reject) => {
            sync()
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    multipleVehiclesForwarding() {
        return new Promise((resolve, reject) => {
            multipleForwarding()
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
