import service from "./api.js";

export function fetchByPage(data) {
    return service({
        url: "/dealers",
        method: "post",
        data
    });
}

export function fetchUnprocessedCount(data) {
    return service({
        url: "/dealers/unprocessed-count",
        method: "get",
        data
    });
}

export function updateStatus(data) {
    return service({
        url: "/dealers/update-status",
        method: "put",
        data
    });
}

export function update(data) {
    return service({
        url: "/dealers/update",
        method: "put",
        data
    });
}

export function multipleUpdate(data) {
    return service({
        url: "/dealers/multiple-update",
        method: "put",
        data
    });
}

export function sync() {
    return service({
        url: "/dealers/sync",
        method: "get"
    });
}
