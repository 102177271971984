<template src="./BlacklistNames.html"></template>

<script>
import { mapGetters, mapActions } from "vuex";
import GenericDataGrid from "@/components/GenericDataGrid";
import PageLeavePopup from "@/components/PageLeavePopup";
import UnsavedChangesPopup from "@/components/UnsavedChangesPopup";
import CustomStore from "devextreme/data/custom_store";
import { store } from "@/store/index";
import notify from "devextreme/ui/notify";
import { generateParams } from "@/utils/params";

const customStore = new CustomStore({
    key: "taLpsNameId",
    async load(loadOptions) {
        await store.dispatch(
            "blacklistNames/fetchBlacklistNames",
            generateParams(loadOptions)
        );

        return store.getters["blacklistNames/getBlacklistNames"];
    },
    totalCount() {
        return store.getters["blacklistNames/getTotalCount"];
    },
    insert() {},
    update() {}
});

export default {
    components: {
        GenericDataGrid,
        PageLeavePopup,
        UnsavedChangesPopup
    },
    name: "BlacklistNames",
    props: {
        unsyncChangesTable: String
    },
    data() {
        return {
            showUnsavedChangesDialog: false,
            hasUnsyncChanges: false,
            isNew: false,
            dataSource: customStore,
            defaultValues: {
                nameActive: true,
                nameRuleLevel: -1,
                nameStartDt: new Date(Date.now()).toISOString()
            },
            defaultColumns: [
                "ID",
                "Typ",
                "Anrede",
                "Vorname",
                "Nachname",
                "Aktiv",
                "Start",
                "LPS Level"
            ]
        };
    },
    computed: {
        ...mapGetters("blacklistNames", ["getBlacklistNames"]),
        formStructure() {
            return [
                {
                    title: "",
                    items: [
                        {
                            label: "Typ",
                            name: "nameType",
                            span: 4
                        },
                        {
                            label: "Anrede",
                            name: "salutation",
                            span: 2
                        },
                        {
                            label: "Vorname",
                            name: "firstName",
                            span: 2
                        },
                        {
                            label: "Nachname",
                            name: "lastName",
                            span: 3
                        },
                        {
                            label: "LPS Level",
                            name: "nameRuleLevel",
                            span: 4
                        },
                        {
                            label: "Start",
                            name: "nameStartDt",
                            span: 3
                        },
                        {
                            name: "nameActive",
                            span: 2,
                            hideLabel: true,
                            editorType: "dxSwitch",
                            editorOptions: {
                                switchedOffText: "Inaktiv",
                                switchedOnText: "Aktiv",
                                width: "80"
                            }
                        },
                        {
                            label: "Letzter Bearbeiter",
                            name: "nameUser",
                            span: 4,
                            hide: this.isNew
                        },
                        {
                            label: "Letzte Änderung",
                            name: "nameTs",
                            span: 4,
                            hide: this.isNew
                        },
                        {
                            label: "ID",
                            name: "lpsNameId",
                            span: 2,
                            hide: this.isNew
                        }
                    ]
                }
            ];
        },
        gridColumns() {
            return [
                {
                    title: "ID",
                    dataField: "lpsNameId",
                    width: 125,
                    blockEditing: true,
                    blockSorting: true,
                    filterOperations: ["startswith"]
                },
                {
                    title: "Typ",
                    dataField: "nameType",
                    required: true,
                    blockSorting: true,
                    lookup: {
                        dataSource: [
                            {
                                id: "Neg_Vor_Nachname",
                                name: "Neg_Vor_Nachname"
                            },
                            { id: "Neg_Nachname", name: "Neg_Nachname" },
                            { id: "Neg_Vorname", name: "Neg_Vorname" },
                            { id: "Neg_Vorname2", name: "Neg_Vorname2" },
                            { id: "Vorname_Anrede", name: "Vorname_Anrede" }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                },
                {
                    title: "Anrede",
                    dataField: "salutation",
                    width: 70,
                    blockSorting: true,
                    blockFiltering: true,
                    editorOptions: { maxLength: 250 }
                },
                {
                    title: "Vorname",
                    dataField: "firstName",
                    blockSorting: true,
                    blockFiltering: true,
                    editorOptions: { maxLength: 250 }
                },
                {
                    title: "Nachname",
                    dataField: "lastName",
                    blockSorting: true,
                    blockFiltering: true,
                    editorOptions: { maxLength: 250 }
                },
                {
                    title: "Aktiv",
                    dataField: "nameActive",
                    width: 60,
                    blockSorting: true,
                    blockFiltering: true,
                    lookup: {
                        dataSource: [
                            { id: false, name: "Inaktiv" },
                            { id: true, name: "Aktiv" }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                },
                {
                    title: "Start",
                    dataField: "nameStartDt",
                    required: true,
                    blockFiltering: true,
                    dataType: "datetime",
                    width: 100,
                    format: "dd.MM.yyyy",
                    editorOptions: {
                        displayFormat: "dd.MM.yyyy",
                        type: "date"
                    }
                },
                {
                    title: "LPS Level",
                    dataField: "nameRuleLevel",
                    required: true,
                    blockSorting: true,
                    blockFiltering: true,
                    width: 180,
                    lookup: {
                        dataSource: [
                            { id: -1, name: "Rot | Requests ablehnen" },
                            { id: -2, name: "Gelb | Manuelle Prüfung" },
                            { id: 0, name: "Grün | Okay, ggf. Parken" }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                },
                {
                    title: "Letzter Bearbeiter",
                    dataField: "nameUser",
                    blockSorting: true,
                    blockFiltering: true,
                    editorOptions: { readOnly: true }
                },
                {
                    title: "Letzte Änderung",
                    dataField: "nameTs",
                    dataType: "datetime",
                    format: "dd.MM.yyyy HH:mm",
                    blockSorting: true,
                    blockFiltering: true,
                    width: 130,
                    editorOptions: { readOnly: true }
                }
            ];
        }
    },
    mounted() {
        if (this.getBlacklistNames.length > 0) {
            const keys = Object.keys(this.getBlacklistNames[0]);
            keys.forEach((key) => {
                if (!this.gridColumns.find((x) => x.dataField === key)) {
                    this.gridColumns.push({
                        title: key,
                        dataField: key,
                        hideInColumnChooser: true
                    });
                }
            });
        }
    },
    methods: {
        ...mapActions("blacklistNames", [
            "fetchBlacklistNames",
            "saveOrUpdateBlacklistName",
            "syncBlacklistNames"
        ]),
        updateRowStatus(isNew) {
            this.isNew = isNew;
        },
        async saveOrUpdate(model) {
            await this.saveOrUpdateBlacklistName(model);
            this.$refs["data-grid-component"].gridReload();
        },
        onSync(payload) {
            if (payload.hasEditData) {
                this.showUnsavedChangesDialog = true;
            } else {
                this.synchronize(payload.updateUnsyncChanges);
            }
        },
        synchronize(updateUnsyncChanges) {
            this.syncBlacklistNames()
                .then(() => {
                    updateUnsyncChanges();
                    notify(
                        {
                            message: "Alle Änderungen synchronisiert",
                            position: {
                                my: "center top",
                                at: "center top"
                            }
                        },
                        "success",
                        3000
                    );
                })
                .catch((error) =>
                    notify(
                        {
                            message: error,
                            position: {
                                my: "center top",
                                at: "center top"
                            }
                        },
                        "error",
                        3000
                    )
                )
                .finally(() => {
                    this.$refs["data-grid-component"].gridReload();
                });
        }
    }
};
</script>
