import unsyncChangesTables from "@/constants/unsyncChangesTables";

const state = {
    unsyncChanges: {
        [unsyncChangesTables.STATUS_NUMBERS]: localStorage[
            unsyncChangesTables.STATUS_NUMBERS
        ]
            ? JSON.parse(localStorage[unsyncChangesTables.STATUS_NUMBERS])
            : false,

        [unsyncChangesTables.SCORE_STEPS]: localStorage[
            unsyncChangesTables.SCORE_STEPS
        ]
            ? JSON.parse(localStorage[unsyncChangesTables.SCORE_STEPS])
            : false,

        [unsyncChangesTables.CAMPAIGNS]: localStorage[
            unsyncChangesTables.CAMPAIGNS
        ]
            ? JSON.parse(localStorage[unsyncChangesTables.CAMPAIGNS])
            : false,

        [unsyncChangesTables.VEHICLES]: localStorage[
            unsyncChangesTables.VEHICLES
        ]
            ? JSON.parse(localStorage[unsyncChangesTables.VEHICLES])
            : false,

        [unsyncChangesTables.DEALERS]: localStorage[unsyncChangesTables.DEALERS]
            ? JSON.parse(localStorage[unsyncChangesTables.DEALERS])
            : false,

        [unsyncChangesTables.RULES]: localStorage[unsyncChangesTables.RULES]
            ? JSON.parse(localStorage[unsyncChangesTables.RULES])
            : false,

        [unsyncChangesTables.BLACKLIST_DIALING_CODES]: localStorage[
            unsyncChangesTables.BLACKLIST_DIALING_CODES
        ]
            ? JSON.parse(localStorage[unsyncChangesTables.BLACKLIST_DIALING_CODES])
            : false,

        [unsyncChangesTables.BLACKLIST_EMAILS]: localStorage[
            unsyncChangesTables.BLACKLIST_EMAILS
        ]
            ? JSON.parse(localStorage[unsyncChangesTables.BLACKLIST_EMAILS])
            : false,
            
        [unsyncChangesTables.BLACKLIST_NAMES]: localStorage[
            unsyncChangesTables.BLACKLIST_NAMES
        ]
            ? JSON.parse(localStorage[unsyncChangesTables.BLACKLIST_NAMES])
            : false
    }
};

const getters = {
    hasUnsyncChanges: (state) => (table) => {
        return state.unsyncChanges[table];
    }
};

const mutations = {
    SET_UNSYNC_CHANGES: (state, data) => {
        state.unsyncChanges[data.table] = data.value;
        localStorage[data.table] = JSON.stringify(data.value);
    }
};

const actions = {
    updateUnsyncChanges({ commit }, data) {
        commit("SET_UNSYNC_CHANGES", data);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
