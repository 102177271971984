<template src="./PreferredDealers.html"></template>

<script>
import { mapGetters, mapActions } from "vuex";
import { store } from "@/store/index";
import DataGridHeader from "@/components/DataGridHeader";
import CustomStore from "devextreme/data/custom_store";
import DxButton from "devextreme-vue/button";
import {
    DxDataGrid,
    DxColumn,
    DxColumnChooser,
    DxToolbar,
    DxItem
} from "devextreme-vue/data-grid";

const customStore = new CustomStore({
    key: "lpsGetDataId",
    loadMode: "raw",
    async load() {
        await store.dispatch("preferredDealers/fetchPreferredDealers");
        return store.getters["preferredDealers/getPreferredDealers"];
    },
    insert() {},
    update() {}
});

export default {
    components: {
        DxDataGrid,
        DxColumnChooser,
        DxToolbar,
        DxItem,
        DxColumn,
        DxButton,
        DataGridHeader
    },
    name: "PreferredDealers",
    data() {
        return {
            dataSource: customStore,
            gridColumns: [
                {
                    title: "ID",
                    dataField: "lpsGetDataId",
                    width: 80,
                    editorOptions: { readOnly: true }
                },
                {
                    title: "RequestID",
                    dataField: "oppObjId",
                    width: 170,
                    editorOptions: { readOnly: true }
                },
                {
                    title: "Buno",
                    dataField: "dealerNbr",
                    width: 60,
                    editorOptions: { readOnly: true }
                },
                {
                    title: "Händler",
                    dataField: "dealerName",
                    width: 140,
                    editorOptions: { readOnly: true }
                },
                {
                    title: "Ausschnitt Weiterleitungstext",
                    dataField: "dealerNameText",
                    editorOptions: { readOnly: true }
                },
                {
                    title: "Weiterleitungstext",
                    dataField: "text",
                    editorOptions: { readOnly: true }
                },
                {
                    title: "Geprüft am",
                    dataField: "checkDoneTs",
                    dataType: "datetime",
                    format: "dd.MM.yyyy HH:mm",
                    width: 140,
                    editorOptions: { readOnly: true }
                }
            ],
            defaultColumns: [
                "ID",
                "RequestID",
                "Buno",
                "Händler",
                "Ausschnitt Weiterleitungstext",
                "Weiterleitungstext",
                "Geprüft am"
            ]
        };
    },
    computed: {
        ...mapGetters("preferredDealers", ["getPreferredDealers"])
    },
    methods: {
        ...mapActions("preferredDealers", [
            "fetchPreferredDealers",
            "decontrolPreferredDealers"
        ]),
        showColumnChooser() {
            this.$refs["data-grid-component"].instance.showColumnChooser();
        },
        async decontrol(lpsId) {
            await this.decontrolPreferredDealers({ Id: lpsId });
            this.$refs["data-grid-component"].instance.getDataSource().reload();
        }
    }
};
</script>

<style src="./PreferredDealers.css"></style>
